import React from 'react';
import { PostalCodeCheckForm } from '@ads-core/components';
import {
  houseNumberAdditionPattern,
  houseNumberPattern,
  postalCodePattern,
} from '@alliander-fe/validation';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formatApiPostalCode } from 'src/utils';

type AddressFilterProps = {
  postalCode?: string;
  houseNumber?: string;
  houseNumberAddition?: string;
};

type FilterAddressProps = {
  onChangeValue: (props: AddressFilterProps) => void;
  value?: AddressFilterProps;
};

export const FilterAddress = ({ onChangeValue, value }: FilterAddressProps) => {
  const { formState, handleSubmit, register } = useForm({
    mode: 'onSubmit',
    values: value,
  });

  const onSubmit: SubmitHandler<AddressFilterProps> = (data) => {
    const { postalCode, houseNumber, houseNumberAddition } = data;

    onChangeValue({
      postalCode: formatApiPostalCode(postalCode) || '',
      houseNumber: houseNumber || '',
      houseNumberAddition: houseNumberAddition || '',
    });
  };

  return (
    <PostalCodeCheckForm handleOnSubmit={handleSubmit(onSubmit)}>
      <PostalCodeCheckForm.Input
        label="Postcode"
        error={formState?.errors?.postalCode?.message as string | undefined}
        placeholder="1234AB"
        {...register('postalCode', {
          pattern: postalCodePattern,
        })}
        autoComplete="postal-code"
      />
      <PostalCodeCheckForm.Addendum
        label="Huisnummer en toevoeging"
        error={formState?.errors?.houseNumber?.message as string | undefined}
        houseNumber={{
          label: 'Huisnummer',
          placeholder: '10',
          ...register('houseNumber', {
            pattern: houseNumberPattern,
          }),
        }}
        addition={{
          label: 'Toevoeging',
          placeholder: 'A',
          ...register('houseNumberAddition', {
            pattern: houseNumberAdditionPattern,
          }),
        }}
      />
      <PostalCodeCheckForm.SubmitButton aria-label="Zoek op locatie">
        Zoeken
      </PostalCodeCheckForm.SubmitButton>
    </PostalCodeCheckForm>
  );
};
