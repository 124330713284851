import React from 'react';
import { useRouter } from 'next/router';
import * as RadixDialog from '@radix-ui/react-dialog';
import { QueryStatus, useQuery } from '@tanstack/react-query';
import { addDays, format, subDays } from 'date-fns';
import { useSitecoreContext } from 'src/context/SitecoreContext';

import { IntegrationApi } from '@alliander-fe/api';
import { postalCodePattern, setReplaceText } from '@alliander-fe/validation';

import {
  Stack,
  TextLink,
  Box,
  Dialog,
  ScrollLinkWrapper,
  Pagination,
  GridItem,
  PageGrid,
  SimpleHeroSitecore,
  Button,
  Heading,
  Alert,
  RichText,
  Loader,
  Text,
  VisuallyHidden,
  JumpToMainContent,
} from '@ads-core/components';
import { ChevronRightIcon, FilterList } from '@ads-core/icons';
import { useMedia } from '@ads-core/hooks';
import { mq } from '@ads-core/breakpoints';

import { capitalizeLocationName, rewriteEnergyType, baseQueryParams } from 'src/utils';
import { useParams } from 'src/hooks/useParams';

import { FilteredOutagesOnLocationProps } from 'components/OutagesOnLocation';

import { OutageBlockComponent } from '../OutageBlock';

import { Filters } from './_Filters';

export const queryParams = baseQueryParams;

export const OutageFilter = (props: FilteredOutagesOnLocationProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { isEditor } = useSitecoreContext();
  const router = useRouter();
  const isDesktop = useMedia(mq.lg);

  // get the url query params
  const { postalCode, houseNumber, houseNumberAddition, startDate, endDate, type, city, page } =
    useParams();

  const limit = 10;
  const pageNumber = Math.max((Number(page) || 0) - 1, 0);

  const path = router.query.path || [];
  const locationIsPostalCode = path ? postalCodePattern.value.test(path[path.length - 1]) : false;
  const locationParams = city || path[path.length - 1] || null;
  const queryLocation = isEditor ? locationParams ?? '' : locationParams;

  // We need a ReportDateFrom and ReportDateTo otherwise the api returns all the results.
  const reportDates = setDates({ startDate, endDate });

  const searchParams = {
    location: queryLocation,
    PostalCode: postalCode || undefined,
    ReportDateFrom: reportDates?.startDate,
    ReportDateTo: reportDates?.endDate
      ? format(addDays(reportDates.endDate, 1), 'yyyy-MM-dd')
      : undefined,
    EnergyType: setEnergyTypRequestParams(type),
  };

  /**
   * Query to get the total count of outages on a location for pagination page count
   * This is a separate call because the Liander API is stupid
   */
  const totalCountQuery = useQuery({
    enabled: !!searchParams,
    queryKey: [searchParams],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        Resolved: true,
        Amount: 0,
        ReturnCountOnly: true,
        ...searchParams,
      }),
  });

  const { status, data } = useQuery({
    enabled: !!searchParams,
    queryKey: [searchParams, limit, pageNumber],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        Resolved: true,
        Amount: limit,
        Offset: pageNumber * limit,
        ReturnCountOnly: false,
        ...searchParams,
      }),
    select: (data) => {
      return {
        ...data,
        outages: data?.outages?.map((outage) => {
          return {
            ...outage,
            energyType: rewriteEnergyType(outage.energyType),
          };
        }),
      };
    },
  });

  /**
   * Request the service and availability when we have a postal code and house number
   * So we can inform the user the address is within the service area.
   */
  const addressQuery = useQuery({
    enabled: Boolean(postalCode && houseNumber),
    queryKey: [postalCode, houseNumber],
    queryFn: () =>
      IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailability({
        postalCode: postalCode,
        houseNumber: Number(houseNumber),
        addition: houseNumberAddition || '',
      }),
  });

  const resultCount = data?.count || 0;
  const totalCount = totalCountQuery.data?.count ?? resultCount;
  const location = locationIsPostalCode
    ? queryLocation?.toUpperCase() ?? ''
    : capitalizeLocationName(queryLocation ?? '');
  const outages = data?.outages;
  const amountOfPages = Math.floor(totalCount / limit);
  const currentPage = pageNumber <= amountOfPages ? pageNumber : amountOfPages;

  const noResultsResponseMessage = noResultsMessage({
    postalCode: !locationIsPostalCode ? postalCode : path[path.length - 1],
    houseNumber,
    startDate: startDate,
    endDate: endDate,
    type: type.split(','),
    city: !locationIsPostalCode ? city || path[path.length - 1] : undefined,
  });

  const alerts = filterAlerts({
    status: status,
    isOperational: addressQuery.data?.isOperational,
    hasOutages: Boolean(outages?.length),
    noResultsMessage: noResultsResponseMessage,
  });

  return (
    <>
      <JumpToMainContent
        id="zoek-resultaten"
        anchor="#opgeloste-onderbrekingen"
        title="Ga naar zoek resultaten"
      />

      <ScrollLinkWrapper anchorId={'opgelosteonderbrekingen'}>
        <Box paddingBottom={{ initial: 6, md: 16 }}>
          <SimpleHeroSitecore
            title={setReplaceText(props?.title?.toString(), {
              '{places}': location ? location : '',
              '{outages}': totalCount ? totalCount.toString() : '',
            })}
            text={
              <Stack gap={2} alignY="end">
                {props.linkOne ? (
                  <TextLink {...props.linkOne} afterIcon={<ChevronRightIcon size="sm" />}>
                    {setReplaceText(props.linkOne.text, { '{places}': location })}
                  </TextLink>
                ) : null}
                {props.linkTwo ? (
                  <TextLink {...props.linkTwo} afterIcon={<ChevronRightIcon size="sm" />}>
                    {setReplaceText(props.linkTwo.text, { '{places}': location })}
                  </TextLink>
                ) : null}
              </Stack>
            }
          />
        </Box>

        <PageGrid>
          {isDesktop ? (
            <GridItem columnStart={'1'} columnEnd={'4'}>
              <Box width="100%">
                <Box width="100%" bg="backgroundLight" borderRadius="brandLg" padding={10}>
                  <VisuallyHidden>
                    <Heading size="h2">Filter</Heading>
                  </VisuallyHidden>
                  <Filters />
                </Box>
              </Box>
            </GridItem>
          ) : (
            <GridItem asChild>
              <Box paddingBottom={6}>
                <Dialog
                  isOpen={isOpen}
                  handleOpenChange={setIsOpen}
                  trigger={
                    <Button afterIcon={<FilterList />} style={{ width: '100%' }}>
                      Filter binnen storingen
                    </Button>
                  }
                >
                  <Box padding={10} asChild>
                    <Stack gap={6}>
                      <RadixDialog.Title asChild>
                        <Heading size="h2">Filter</Heading>
                      </RadixDialog.Title>
                      <RadixDialog.Description asChild>
                        <Stack gap={6}>
                          <Box>
                            <Filters handleOpenChange={setIsOpen} />
                          </Box>
                          <Button onClick={() => setIsOpen(false)} afterIcon={<FilterList />}>
                            Filter binnen storingen
                          </Button>
                        </Stack>
                      </RadixDialog.Description>
                    </Stack>
                  </Box>
                </Dialog>
              </Box>
            </GridItem>
          )}

          <GridItem columnStart={{ initial: '1', lg: '4' }} columnEnd={'-1'}>
            <ScrollLinkWrapper anchorId="opgeloste-onderbrekingen">
              <Stack gap={{ initial: 8, md: 20 }} isFullWidth>
                <Stack direction="column" gap={4} isFullWidth>
                  <Alert variant={status === 'error' ? 'error' : 'info'}>
                    {alerts ? <RichText>{alerts}</RichText> : null}
                  </Alert>

                  {status === 'pending' ? (
                    <div aria-live="polite" role="alert">
                      <Box
                        position="absolute"
                        top="0px"
                        left="0px"
                        bottom="0px"
                        right="0px"
                        zIndex="2"
                        asChild
                        style={{ backdropFilter: 'blur(5px)' }}
                      >
                        <Stack alignX="center" alignY="center">
                          <Heading size="h2" asChild>
                            <Text>
                              Laden... <Loader />
                            </Text>
                          </Heading>
                        </Stack>
                      </Box>
                    </div>
                  ) : null}

                  {outages?.map((outage, index) => (
                    <OutageBlockComponent {...outage} {...props} allResolvedOutages key={index} />
                  ))}
                </Stack>

                {outages ? (
                  <Pagination
                    forcePage={currentPage}
                    pageCount={totalCount ? totalCount / limit : 0}
                    onPageChange={(e) => {
                      const selectedPage = e.selected + 1;
                      const activeFilters = new URLSearchParams();
                      const { path, ...query } = router.query;
                      const [pathname] = router.asPath.split('?');

                      activeFilters.set(baseQueryParams.page, selectedPage.toString());

                      router.push({
                        pathname: pathname,
                        query: {
                          ...query,
                          [queryParams.page]: selectedPage,
                        },
                      });
                    }}
                  />
                ) : null}
              </Stack>
            </ScrollLinkWrapper>
          </GridItem>
        </PageGrid>
      </ScrollLinkWrapper>
    </>
  );
};

/**
 * Maps the filter energy type and return te correct value for te api.
 * When both stroom, gas exist we return undefined because the api accept only value 'Elektriciteit', 'Gas' or undefined .
 * There's no filtering on undefined so we get the results for 'Gas' and 'Elektriciteit'
 *
 * @param type 'stroom, gas' | 'stroom' | 'gas' | undefined
 * @returns Elektriciteit | Gas | undefined
 */
const setEnergyTypRequestParams = (type: string | undefined) => {
  const isElectric = type?.includes('stroom');
  const isGas = type?.includes('gas');

  switch (true) {
    case isElectric && !isGas:
      return 'Elektriciteit';
    case isGas && !isElectric:
      return 'Gas';
    default:
      return undefined;
  }
};

type Dates = {
  startDate?: string;
  endDate?: string;
};

/**
 * Checks if theres a end and start date and adds the min or max date if its missing.
 * @param startDate '2024-11-01' | '' | undefined
 * @param endDate '2024-11-28' | '' | undefined
 * @returns Dates | undefined
 */
const setDates = ({ startDate, endDate }: Dates): Dates | undefined => {
  if (!startDate && !endDate) return;

  if (startDate && endDate) return { startDate, endDate };

  const today = new Date();
  const maxDate = format(today, 'yyyy-MM-dd');
  const minDate = format(subDays(today, 365), 'yyyy-MM-dd');

  if (startDate) {
    return {
      startDate,
      endDate: maxDate,
    };
  }

  return {
    endDate,
    startDate: minDate,
  };
};

type FilterAlertsProps = {
  hasOutages?: boolean;
  isOperational?: boolean;
  status?: QueryStatus;
  noResultsMessage: string;
};

/**
 * Returns a message for the Alert Component.
 * @param status
 * @param isOperational
 * @param hasOutages
 * @returns string | undefined
 */
const filterAlerts = ({
  status,
  isOperational,
  hasOutages,
  noResultsMessage,
}: FilterAlertsProps) => {
  switch (true) {
    case status === 'error':
      return 'Er is iets fout gegaan, probeer het opnieuw.';
    case isOperational === false:
      return 'We zien aan uw postcode dat Liander niet uw netbeheerder is. Controleer uw gegevens of zoek uw netbeheerder op via <a href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank"> mijnaansluiting.nl</a>.';
    case status === 'success' && !hasOutages && isOperational !== false:
      return noResultsMessage;
    default:
      return undefined;
  }
};

type NoResultsMessage = {
  postalCode?: string;
  houseNumber?: string;
  startDate?: string;
  endDate?: string;
  type?: string[];
  city?: string;
};

const noResultsMessage = ({
  postalCode,
  houseNumber,
  startDate,
  endDate,
  type,
  city,
}: NoResultsMessage) => {
  const energieType = type?.length === 1 ? type : '';
  const formattedStartDate = startDate ? format(startDate, 'dd-MM-yyyy') : undefined;
  const formattedEndDate = endDate ? format(endDate, 'dd-MM-yyyy') : undefined;

  let activeFilters = '';

  if (city) {
    activeFilters = `in ${city}`;
  }

  if (postalCode) {
    activeFilters = activeFilters
      ? `${activeFilters}, postcode ${postalCode}`
      : ` op postcode ${postalCode}`;
  }

  if (houseNumber) {
    activeFilters = activeFilters
      ? `${activeFilters}, huisnummer ${houseNumber}`
      : `op huisnummer ${houseNumber}`;
  }

  if (!formattedStartDate && formattedEndDate) {
    activeFilters = activeFilters
      ? `${activeFilters}, tot ${formattedEndDate}`
      : `tot ${formattedEndDate}`;
  }

  if (formattedStartDate && !formattedEndDate) {
    activeFilters = activeFilters
      ? `${activeFilters}, vanaf ${formattedStartDate}`
      : `vanaf ${formattedStartDate}`;
  }

  if (formattedStartDate && formattedEndDate) {
    activeFilters = activeFilters
      ? `${activeFilters}, tussen ${formattedStartDate} en ${formattedEndDate}`
      : `tussen ${formattedStartDate} en ${formattedEndDate}`;
  }

  return `Er zijn geen opgeloste ${energieType}storingen gevonden ${activeFilters}. Wilt u een postcode in een andere plaats zoeken? Ga dan naar het <a href="/storingen-en-onderhoud/opgeloste-storingen">overzicht van alle plaatsen</a> en kies eerst de juiste plaats.`;
};
