import React from 'react';
import { PostalCodeCheckFormProps } from '@ads-core/types';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { baseQueryParams } from 'src/utils';
import { trackPostalcodeCheckError } from 'src/context/TrackingContext';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { FilterValuePropertyFromFields } from 'src/utils/mapWithoutValueProperty';
import { PostalCodeCheckForm } from '@ads-core/components';
import { postalCodePattern, requiredFieldPattern } from '@alliander-fe/validation';
import { TextFieldProps } from '@alliander-fe/sitecore-types';
import { extractString } from '../Form/utils/utils';

export type CongestionCheckFormProps = {
  congestionCheck?: FilteredCongestionCheckTasksProps;
  color?: PostalCodeCheckFormProps['color'];
};

type InputsProps = {
  postalCode: string;
};

export const CongestionCheckForm = ({ congestionCheck, color }: CongestionCheckFormProps) => {
  const router = useRouter();
  const [postalCodeError, setPostalCodeError] = React.useState<string>();

  const { handleSubmit, formState, register } = useForm({
    mode: 'onTouched',
  });

  if (!congestionCheck) return null;

  const onSubmit: SubmitHandler<InputsProps> = async (data: InputsProps) => {
    const postalCodeIsValid = postalCodePattern.value.test(data.postalCode);

    if (!postalCodeIsValid) {
      const error = postalCodePattern.message;
      trackPostalcodeCheckError(error);
      setPostalCodeError(error);
      return;
    }

    setPostalCodeError(undefined);
    const params = new URLSearchParams();
    params.set(baseQueryParams.postalCode, data.postalCode.toUpperCase());
    const parsedParams = decodeURIComponent(params.toString());

    router.push(
      {
        pathname: congestionCheck.redirectPage.href,
        search: parsedParams,
      },
      undefined,
      { scroll: false }
    );
  };

  return (
    <PostalCodeCheckForm handleOnSubmit={handleSubmit(onSubmit)}>
      <PostalCodeCheckForm.Container color={color}>
        <PostalCodeCheckForm.Heading>
          {congestionCheck.title ?? 'Bekijk de beschikbare capaciteit op uw adres.'}
        </PostalCodeCheckForm.Heading>
        <PostalCodeCheckForm.Input
          label={congestionCheck?.postalCode?.toString() ?? 'Postcode'}
          tone="onDark"
          placeholder="1234AB"
          error={extractString(formState?.errors?.postalCode?.message) || postalCodeError}
          {...register('postalCode', {
            required: requiredFieldPattern(congestionCheck?.postalCode?.toString() ?? 'Postcode'),
            pattern: postalCodePattern,
          })}
          autoComplete="postal-code"
        />
        <PostalCodeCheckForm.SubmitButton size="large" tone="onDark" variant="secondary">
          {congestionCheck?.button ?? 'Controleren'}
        </PostalCodeCheckForm.SubmitButton>
      </PostalCodeCheckForm.Container>
    </PostalCodeCheckForm>
  );
};

export type SiteCoreCongestionCheckProp = {
  redirectPage: LinkField;
  button: TextFieldProps;
  postalCode: TextFieldProps;
  title: TextFieldProps;
};

export type FilteredCongestionCheckTasksProps =
  FilterValuePropertyFromFields<SiteCoreCongestionCheckProp>;
