import React from 'react';
import type { PreviewSearchActionProps } from '@sitecore-search/widgets';
import { Stack, StackItem, ToneFocus, Text, Box } from '@ads-core/components';
import { ChevronRightIcon } from '@ads-core/icons';
import { InternalOrExternalLink } from '../InternalOrExternalLink';
import { HighlightText } from './_HighlightedText';
import { SuggestionData } from '.';

export const Results = ({
  results,
  keyphrase,
}: {
  results: SuggestionData[];
  keyphrase: string;
  onItemClick: PreviewSearchActionProps['onItemClick'];
}) => {
  return (
    <Stack gap={2} direction="column" as="ul">
      {results.map((item, index) => (
        <Item item={item} keyphrase={keyphrase} key={`${item.term}-${index}`} />
      ))}
    </Stack>
  );
};

export const Item = ({ item, keyphrase }: { item: SuggestionData; keyphrase: string }) => {
  if (!item.term || !item.count || !item.url) {
    return null;
  }

  return (
    <li>
      <Stack gap={1} direction="row" alignY="start" isFullWidth asChild>
        <ToneFocus tone="onLight">
          <InternalOrExternalLink href={item.url}>
            <Box style={{ paddingTop: '1px' }} as="span">
              <ChevronRightIcon size="sm" />
            </Box>
            <StackItem asChild>
              <Stack gap={2} direction="row" alignX="justify" alignY="start" as="span" isFullWidth>
                <HighlightText text={item.term} term={keyphrase} />
                <Text as="span">{item.count ? `${item.count}` : null}</Text>
              </Stack>
            </StackItem>
          </InternalOrExternalLink>
        </ToneFocus>
      </Stack>
    </li>
  );
};
