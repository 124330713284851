import { Field } from '../types';
import { isButtonField, isSectionField } from "./utils";

/**
 * Get all button fields from the form
 * @param fields - The fields to search through
 * @returns An array of button fields
 */
export function getButtonField(fields: Field[]): Field[] {
  const buttons: Field[] = [];

  for (const field of fields) {
    if (isButtonField(field)) {
      buttons.push(field);
    }

    if (isSectionField(field)) {
      buttons.push(...getButtonField(field.fields));
    }
  }

  return buttons;
}