import { useMemo } from 'react';
import { Box, Text } from '@ads-core/components';
import { separateTextOnTerm } from './utils/separateTextOnTerm';

export const HighlightText = ({ text, term }: { text: string; term: string }) => {
  const highlightedText = useMemo(() => separateTextOnTerm(text, term), [text, term]);

  return (
    <Box as="span">
      {highlightedText.map((text, index) =>
        text.toLocaleLowerCase() === term.toLocaleLowerCase() ? (
          <Text weight="semiBold" as="span" key={`${text}-${index}`}>
            {text}
          </Text>
        ) : (
          <Text as="span" key={`${text}-${index}`}>
            {text}
          </Text>
        )
      )}
    </Box>
  );
};
