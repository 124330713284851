import { Button, Stack, VisuallyHidden } from '@ads-core/components';
import { useMedia } from '@ads-core/hooks';
import { mq } from '@ads-core/breakpoints';
import { ArrowLeftIcon, ArrowRightIcon } from '@ads-core/icons';
import { ButtonFormField } from '@sitecore-jss/sitecore-jss-forms';
import { FieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import { useIsConditionallyHidden } from '../hooks';
import { ButtonWithConditions } from '../types';

type Props = ButtonWithConditions<FieldProps<ButtonFormField>>;

export const ButtonMapper = ({ field, currentPage, totalAmountOfSteps, onClick }: Props) => {
  const isDesktop = useMedia(mq.md);
  const name = field.buttonField.name;
  const title = field.model.title;
  const submitButton = field.model.navigationStep !== -1;
  const { formState } = useFormContext();

  const { fieldKey } = field.model.conditionSettings;
  const isHidden = useIsConditionallyHidden({ fieldKey });

  if (isHidden) return null;

  return (
    <Stack direction={'row'} gap={{ initial: 2, md: 4 }}>
      {submitButton && totalAmountOfSteps > 1 && currentPage !== 1 ? (
        <Button
          disabled={formState.isSubmitting}
          isLoading={formState.isSubmitting}
          type={'button'}
          className={field.model.cssClass}
          value={name}
          name={name}
          style={{ alignSelf: 'flex-start' }}
          onClick={() => (onClick ? onClick(-1) : undefined)}
          variant={'ghost'}
          beforeIcon={<ArrowLeftIcon size={{ initial: 'xs', md: 'sm' }} />}
        >
          {isDesktop ? 'Vorige stap' : <VisuallyHidden>{'Vorige stap'}</VisuallyHidden>}
        </Button>
      ) : null}
      <Button
        disabled={formState.isSubmitting}
        isLoading={formState.isSubmitting}
        type={submitButton ? 'submit' : 'button'}
        className={field.model.cssClass}
        value={name}
        name={name}
        style={{ alignSelf: 'flex-start' }}
        onClick={() => (onClick && !submitButton ? onClick(-1) : undefined)}
        variant={submitButton ? 'primary' : 'ghost'}
        afterIcon={submitButton ? <ArrowRightIcon size={{ initial: 'xs', md: 'sm' }} /> : undefined}
        beforeIcon={
          !submitButton ? <ArrowLeftIcon size={{ initial: 'xs', md: 'sm' }} /> : undefined
        }
      >
        {isDesktop || submitButton ? title : <VisuallyHidden>{title}</VisuallyHidden>}
      </Button>
    </Stack>
  );
};
