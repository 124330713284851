import React from 'react';
import * as Popover from '@radix-ui/react-popover';

import { Box } from '../Box';
import { Error } from '../InputBase';
import { filterOptions } from './utils/filterOptions';

import { ComboBoxContent } from './_ComboBoxContent';
import { ComboBoxTrigger } from './_ComboBoxTrigger';

import { CombBoxFilter } from './_CombBoxFilter';
import { ListboxToggleVariants } from './ComboBox.css';

export type ComboBoxProps = {
  label: string;
  error?: string;
  options: { label: string; value: string }[];
  handleOnChange: (option: { label: string; value: string }) => void;
  value?: string;
  placeholder: string;
  noResultsText?: string;
  searchFieldPlaceholder?: string;
  searchFieldLabel?: string;
} & Omit<ListboxToggleVariants, 'isOpen'>;

export const ComboBox = ({
  label,
  error,
  options,
  placeholder,
  handleOnChange,
  value,
  tone,
  searchFieldLabel,
  searchFieldPlaceholder,
}: ComboBoxProps) => {
  const id = React.useId();
  const toggleRef = React.useRef<HTMLButtonElement>(null);
  const errorId = error ? `${id}-error` : undefined;
  const popOverWidth = toggleRef.current?.offsetWidth
    ? `${toggleRef.current?.offsetWidth}px`
    : 'auto';
  const [isOpen, setIsOpen] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const filteredOptions = filterOptions(options, filter);
  const selectedOption = options.find((option) => option.value === value);

  const handleClose = (open: boolean) => {
    setIsOpen(open);
  };

  const handleNewSelection = (option: { label: string; value: string }) => {
    handleOnChange(option);
    setFilter('');
    handleClose(false);
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={handleClose}>
      <Box width="100%">
        <ComboBoxTrigger
          id={id}
          label={label}
          value={selectedOption}
          isOpen={isOpen}
          error={error}
          placeholder={placeholder}
          ref={toggleRef}
          tone={tone}
        />
        {error ? <Error id={errorId}>{error}</Error> : null}
        <ComboBoxContent
          filter={
            <CombBoxFilter
              filterValue={filter}
              handleFilterChange={setFilter}
              searchFieldLabel={searchFieldLabel}
              searchFieldPlaceholder={searchFieldPlaceholder}
            />
          }
          id={id}
          handleOnChange={handleNewSelection}
          options={filteredOptions}
          value={selectedOption}
          width={popOverWidth}
        />
      </Box>
    </Popover.Root>
  );
};
