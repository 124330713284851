import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import type { MappedMainMenu } from '@alliander-fe/sitecore-types';
import { Stack } from '../Stack/index';
import { Box } from '../Box';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { ChevronRightIcon, ChevronDownIcon } from '../../icons/index';
import { NavLink } from '../NavLink';
import { ToneFocus } from '../ToneFocusProvider';
import { useAdsContext } from '../../providers';
import * as styles from './Navigation.css';

type SubListProps = {
  title: string;
  items: { link: React.ReactElement }[];
};

const SubList = ({ title, items }: SubListProps) => {
  return (
    <>
      <Heading size="h5" className={styles.subListTitle} asChild>
        <h3 id={title}>{title}</h3>
      </Heading>
      <Stack asChild gap={4} className={styles.subListList}>
        <ul aria-describedby={title}>
          {items.map((item) => {
            return (
              <li key={item.link.props.href}>
                <NavigationMenu.Link asChild>
                  <NavLink
                    className={styles.navLink}
                    beforeIcon={<ChevronRightIcon size="sm" />}
                    asChild
                  >
                    {item.link}
                  </NavLink>
                </NavigationMenu.Link>
              </li>
            );
          })}
        </ul>
      </Stack>
    </>
  );
};

const NavigationItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text asChild size="paragraph">
      <NavigationMenu.Trigger
        asChild
        className={styles.menuTrigger}
        onPointerMove={(event) => event.preventDefault()}
        onPointerLeave={(event) => event.preventDefault()}
      >
        <NavLink
          asChild
          size="large"
          afterIcon={<ChevronDownIcon size="sm" className={styles.caret} />}
        >
          <button>{children}</button>
        </NavLink>
      </NavigationMenu.Trigger>
    </Text>
  );
};

/**
 * If you want to force open the dropdown, you can debug the dropdown by adding a `value="id-1"` to the NavigationMenu.Root and NavigationMenu.Item. That makes it controlled and force the dropdown to be open.
 */

export const Navigation = (props: {
  items: MappedMainMenu;
  search: React.ReactNode;
  logo: React.ReactElement;
}) => {
  const topHeaderRef = React.useRef<HTMLDivElement>(null);

  const adsContext = useAdsContext();
  const offset = 20;

  React.useEffect(() => {
    const topHeaderHeight = (topHeaderRef.current?.clientHeight || 0) + offset;
    adsContext.setTopHeaderHeight(topHeaderHeight);
  }, [adsContext]);

  return (
    <NavigationMenu.Root className={styles.root}>
      <Stack
        alignX="justify"
        direction="row"
        alignY="center"
        isFullWidth
        ref={topHeaderRef}
      >
        <Stack gap={16} direction="row" alignY="center" isFullHeight>
          <ToneFocus>{props.logo}</ToneFocus>

          <Stack direction="row" isFullHeight>
            <NavigationMenu.List className={styles.menuList}>
              {props.items.map((item) => {
                return (
                  <NavigationMenu.Item key={item.title}>
                    <NavigationItem>{item.title}</NavigationItem>
                    <NavigationMenu.Content
                      className={styles.menuContent}
                      onPointerLeave={(e) => e.preventDefault()}
                    >
                      <Box
                        paddingTop={20}
                        paddingBottom={20}
                        paddingLeft={24}
                        paddingRight={24}
                      >
                        <Stack
                          direction="row"
                          gap={14}
                          isFullWidth
                          className={styles.subListWrapper}
                        >
                          {item.subMenu.map((subItem) => {
                            return (
                              <SubList
                                key={subItem.subHeader}
                                title={subItem.subHeader}
                                items={subItem.links}
                              />
                            );
                          })}
                        </Stack>
                      </Box>
                    </NavigationMenu.Content>
                  </NavigationMenu.Item>
                );
              })}
              <NavigationMenu.Indicator className={styles.menuIndicator}>
                <div className={styles.menuArrow} />
              </NavigationMenu.Indicator>
            </NavigationMenu.List>
          </Stack>

          <div className={styles.viewportPosition}>
            <NavigationMenu.Viewport className={styles.menuViewport} />
          </div>
        </Stack>
      </Stack>
      <NavigationMenu.Item asChild>{props.search}</NavigationMenu.Item>
    </NavigationMenu.Root>
  );
};
