import { WidgetDataType, widget } from '@sitecore-search/react';
import { Box } from '@ads-core/components';
import React from 'react';
import { debounce } from '@sitecore-search/common';
import { PreviewSearch } from 'src/components/SearchResults';
import { useSearch } from 'src/components/SearchResults/hooks/useSearch';

export const PreviewSearchBasicComponent = ({ defaultKeyphrase = '', defaultItemsPerPage = 8 }) => {
  const suggestionsList = [{ suggestion: 'keyword_autocomplete', max: 5 }];

  const {
    widgetRef,
    keyphrase,
    onItemClick,
    onKeyphraseChange,
    isFetching,
    isSuccess,
    isError,
    suggestionData,
    query,
  } = useSearch({
    defaultKeyphrase,
    defaultItemsPerPage,
    suggestionsList,
  });

  const keyphraseHandler = debounce((e: React.ChangeEvent<HTMLInputElement> | KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    query.isEnabled = () => true;
    onKeyphraseChange({
      keyphrase: target.value,
    });
  }, 400);

  return (
    <Box ref={widgetRef}>
      <PreviewSearch
        ref={widgetRef}
        keyphrase={keyphrase}
        onKeyphraseChange={keyphraseHandler}
        onItemClick={onItemClick}
        isFetching={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        suggestionData={suggestionData ?? []}
      />
    </Box>
  );
};
const PreviewSearchBasicWidget = widget(
  PreviewSearchBasicComponent,
  WidgetDataType.PREVIEW_SEARCH,
  'content'
);

export default PreviewSearchBasicWidget;
