/**
 * Separate the text on the term.
 * @param result - The result string.
 * @param term - The term on which the user searches.
 * @returns A array of strings of the result split on the term.
 */
export const separateTextOnTerm = (result: string, term: string): string[] => {
  if (!term || !result) return [result];

  const resultArray = [];

  // We create a case insensitive regex to match the term.
  const regex = new RegExp(term, 'i');

  // We split the result on the term, so we can highlight it.
  const splitResult = result.split(regex);
  const amountOfKeys = splitResult.length;
  
  // We lookup the original text so we can add it back so the results stays the same.
  const match = result.match(regex);
  const originalTermText = match ? match[0] : undefined;


  // adds the term back to the result array
  for (const result of splitResult) {
    const index = splitResult.indexOf(result);
    resultArray.push(result);

    if (index < amountOfKeys - 1) {
      resultArray.push(originalTermText ?? term);
    }
  }

  return resultArray;
};
