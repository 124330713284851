import React, { useEffect, useRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { useRouter } from 'next/router';
import {
  Box,
  Text,
  Stack,
  TextLink,
  Heading,
  RichText,
  Button,
  Grid,
  GridItem,
  StackItem,
  Tag,
} from '@ads-core/components';
import { useTrackingContext } from '@liander/context';
import { setReplaceText } from '@alliander-fe/validation';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { outageEndDateAndTime, setOutageEndDate } from 'src/utils';
import { capitalizeLocationName, formatCityListIntl } from 'src/utils/format';
import { causeMapper, isOutageCause } from 'src/utils';

import { IntegrationApiFeaturesOutagesContractsDataOutage } from '@alliander-fe/api';
import { PolygonMap } from '../PolygonMap';

export type OutageData = {
  title?: string;
  statusPhases?: string[];
  statusDescription?: string;
  postalCode?: string;
} & IntegrationApiFeaturesOutagesContractsDataOutage;

export type OutageDialogContentProps = {
  outageDetailsText?: string;
  outageDetailsLinkOne?: ButtonProps;
  outageDetailsLinkTwo?: ButtonProps;
  handleClose: () => void;
} & Omit<OutageData, 'topTasks' | 'followUpSteps'>;

export const OutageDialogContent = ({
  outageDetailsLinkOne,
  outageDetailsLinkTwo,
  outageDetailsText,
  handleClose,
  ...outageData
}: OutageDialogContentProps) => {
  const { trackPageInteraction } = useTrackingContext();
  const isMounted = useRef<boolean>(false);
  const router = useRouter();
  const isMaintenance = !isOutageCause(outageData.cause);
  const resolvedOutages = outageData.status?.toLowerCase() === 'opgelost';

  const onClose = (route?: string) => {
    handleClose();

    if (route) {
      router.push(route);
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      trackPageInteraction({
        actionDetail: outageData.title ?? '',
        actionType: 'modal_view',
      });

      isMounted.current = true;
    }
  }, [isMounted, outageData.title, trackPageInteraction]);

  if (!outageData || (!outageData.status && !outageData.statusDescription)) return null;

  const endDateAndTime = outageEndDateAndTime({
    ...outageData,
    resolvedOutages,
  });

  return (
    <Box
      paddingTop={{ initial: 8, md: 14 }}
      paddingInline={{ initial: 8, md: 20 }}
      paddingBottom={{ initial: 8, md: 14 }}
      asChild
    >
      <Stack gap={8} isFullWidth>
        {resolvedOutages ? (
          <Box>
            <Tag>Opgelost</Tag>
          </Box>
        ) : null}
        {outageData.title ? (
          <Dialog.Title asChild>
            <Box paddingRight={{ initial: 20, md: 6 }} asChild>
              <Heading size="h2">{outageData.title}</Heading>
            </Box>
          </Dialog.Title>
        ) : null}

        <Dialog.Description asChild>
          <Stack gap={8} isFullWidth>
            <Grid rowGap={2} columnGap={{ initial: 2, md: 4 }} columns={2}>
              <GridItem columnStart="1" columnEnd="2">
                <Text size="description" weight="bold">
                  {isMaintenance ? 'Start werkzaamheden:' : 'Storing gemeld:'}
                </Text>
              </GridItem>
              <GridItem columnStart={'2'} columnEnd="-1">
                <Text size="description">{displayReportTime(outageData.reportTime)}</Text>
              </GridItem>

              {endDateAndTime ? (
                <>
                  <GridItem columnStart="1" columnEnd="2">
                    <Text size="description" weight="bold">
                      {endDateAndTime.label}
                    </Text>
                  </GridItem>
                  <GridItem columnStart={'2'} columnEnd="-1">
                    <Text size="description">{endDateAndTime.description}</Text>
                  </GridItem>
                </>
              ) : null}

              <GridItem columnStart="1" columnEnd="2">
                <Text size="description" weight="bold">
                  Oorzaak:
                </Text>
              </GridItem>
              <GridItem columnStart={'2'} columnEnd="-1">
                <Text size="description">{causeMapper(outageData.cause)}</Text>
              </GridItem>
              <GridItem columnStart="1" columnEnd="2">
                <Text size="description" weight="bold">
                  Aantal getroffen:
                </Text>
              </GridItem>
              <GridItem columnStart={'2'} columnEnd="-1">
                <Text size="description">{outageData.affectedCustomers}</Text>
              </GridItem>
              <GridItem columnStart="1" columnEnd="2">
                <Text size="description" weight="bold">
                  Referentienummer:
                </Text>
              </GridItem>
              <GridItem columnStart={'2'} columnEnd="-1">
                <Text size="description">{outageData.outageNumber}</Text>
              </GridItem>
              {outageData.affectedPlaces ? (
                <>
                  <GridItem columnStart="1" columnEnd="2">
                    <Text size="description" weight="bold">
                      Plaats:
                    </Text>
                  </GridItem>
                  <GridItem columnStart={'2'} columnEnd="-1">
                    <Text size="description">
                      {formatCityListIntl(
                        outageData.affectedPlaces.split(';').map(capitalizeLocationName)
                      )}
                    </Text>
                  </GridItem>
                </>
              ) : null}
              <GridItem columnStart="1" columnEnd="2">
                <Text size="description" weight="bold">
                  Straten:
                </Text>
              </GridItem>
              <GridItem columnStart={'2'} columnEnd="-1">
                <Text size="description">{outageData.affectedStreets}</Text>
              </GridItem>
              <GridItem columnStart="1" columnEnd="2">
                <Text size="description" weight="bold">
                  Postcodes:
                </Text>
              </GridItem>
              <GridItem columnStart={'2'} columnEnd="-1">
                <Text size="description">{outageData.affectedPostalCodes}</Text>
              </GridItem>
            </Grid>

            {outageDetailsText ? <RichText>{outageDetailsText}</RichText> : null}

            {outageDetailsLinkTwo?.href || outageDetailsLinkOne?.href ? (
              <Stack
                alignY={{ initial: 'start', md: 'center' }}
                alignX="start"
                isFullWidth
                direction={{ initial: 'column', md: 'row' }}
                gap={{ initial: 4, md: 6 }}
              >
                {outageDetailsLinkOne?.href ? (
                  <StackItem grow asChild>
                    <Button
                      onClick={() => {
                        trackPageInteraction({
                          actionDetail: outageDetailsLinkOne?.text ?? '',
                          actionType: 'link_click',
                        });

                        onClose(outageDetailsLinkOne?.href);
                      }}
                    >
                      {outageDetailsLinkOne?.text}
                    </Button>
                  </StackItem>
                ) : null}

                {outageDetailsLinkTwo?.href && outageDetailsLinkTwo?.text ? (
                  <StackItem grow>
                    <TextLink
                      href={outageDetailsLinkTwo?.href}
                      onClick={() => {
                        trackPageInteraction({
                          actionDetail:
                            setReplaceText(outageDetailsLinkTwo.text, {
                              '{outageCause}': `geen ${outageData.energyType}`,
                            }) ?? '',
                          actionType: 'link_click',
                        });

                        onClose(outageDetailsLinkTwo?.href);
                      }}
                    >
                      <Text size="description">
                        {setReplaceText(outageDetailsLinkTwo?.text, {
                          '{outageCause}': `geen ${outageData.energyType}`,
                        })}
                      </Text>
                    </TextLink>
                  </StackItem>
                ) : null}
              </Stack>
            ) : null}

            {outageData?.rings || outageData?.affectedPostalCodes ? (
              <Box width="100%" height="176px" borderRadius="brandXl" overflow="hidden">
                <PolygonMap
                  rings={outageData?.rings ? outageData.rings : undefined}
                  postalCode={
                    outageData?.affectedPostalCodes
                      ? getFirstPostalCode(outageData.affectedPostalCodes)
                      : undefined
                  }
                  isLoading={!outageData}
                />
              </Box>
            ) : null}
          </Stack>
        </Dialog.Description>
      </Stack>
    </Box>
  );
};

// Refactor the string in case of a date and adds a year.
const displayReportTime = (reportTime?: string | null | undefined) => {
  if (reportTime && !reportTime.startsWith('vandaag')) {
    const splitReportTime = reportTime.split(' ');

    if (splitReportTime.length > 2) {
      const date = `${splitReportTime[0]} ${splitReportTime[1]}`;
      const time = splitReportTime.slice(2, splitReportTime.length).toString().replace(',', ' ');

      const outageDate = setOutageEndDate(date);

      return `${outageDate} ${time}`;
    }
  }

  return reportTime;
};

// selects the first postal code from a list of postalCodes.
const getFirstPostalCode = (affectedPostalCodes?: string): string | undefined => {
  if (affectedPostalCodes) {
    const postalCodes = affectedPostalCodes.split(', ');
    const postalCode = postalCodes.length ? postalCodes[0] : undefined;

    return postalCode ? postalCode.substring(0, 4) + ' ' + postalCode.substring(4) : postalCode;
  }

  return undefined;
};
