/**
 * @description This function is used to format the postal code to the correct format
 * @param value - The postal code to format
 * @returns The formatted postal code
 */
export const formatC4PostalCode = (value: string) => {
  if (!value) return '';

  // sets te format for the postal code
  return value.substring(0, 6).toUpperCase().replace(/\s+/g, '');
};
