import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fglobal%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VW227jNhB971cMYCzQBRyBHFKkxD75%2Blj0DwrJkmwlvsVyHCdF%2F70gG2%2BhIafA2ojgnDM3DXnIyf6UQ6%2BOUgn46xeAp6fH%2F9LBBOdYoP1thKODyTp8xrhyMClQruxyjGsHk5XwnzGeO5gIYcuZGuPGwUSvJerFGLdM%2FIKpp2TwisFrJv4m1Jnn1ozxhqm%2FZeJ3DL5l8B3T%2F56p55mp%2F4WJs2fiHJj3OjJ1nhj8zOCvDH5h8IGp%2F8rYvzH2N8b%2BncHvTJwPxv6TwaXgCMkRXmJd7r%2BE8Brr5EaLjhBeZLKqN%2BWGEDmXw8tsPVvrNZGltMy%2BkMWPhijqU%2FIUJzdZcwVsmMbLhtnh0ktutVguV%2BTIkF5zZiFLUxDCiy5fyDwvCbFjTh%2FZM7KQXnfLcrVYU%2BIleJiiWBHiS3kLS1ty4FrvtWeXc61oVV58q3wtlvQFvfraprNtQwgvvzbvTEcJr7%2Bu63RLN50XYNe0TRTqyrXkjXuPG%2BcRRDgrraS98ipcClsK2qsPB9dLdRzO1aU9Xgn5yd1d4n%2B8kNMjItN%2BVByhma2KObO%2F0HAelvMomGZiybQfK0ZyyN19uOFa0nAdbpmVxI4jtlyoHZe858r1Wpyv56s5zREuwdY2CgkRtIhF0UhCHIKAzEZQD6%2FFRmGH5AzGE7cZvBZnKp6A8NWBON9BCv8s%2FOOyratflZ0CyimoKYhM4PdpsNIys9aa8x2UyjSiTJtLrayVXz6ImVJG%2BRw2K0zJuMgS8eEiMctRmlBWJqRFxufL3GQmF76UPFMoRJG2FoWwtjCPqjJrLJrwC6UqmDcRuUEs5XfStIuD%2Fji0VxDh653T22dIGCa309UFcjjt%2B4aJ9TYySUa5OfC9aqph1zaMzt7HNkn13h3o852AH%2BwFi%2F%2FNHZRSgtFJmO1TycNwn2pAmO5Tp5DiTjrFTR5hwE%2FmsMxZH0b8lLLCjJ9SVhjy66Yp65YQ%2FqSrNk3dEVmHMb%2BrG6zIBBXm%2FE7VbRSqDeolYBdWmIDbxJL6%2Bb6IwN6B8PDjj9DPnv5XnI8HsXhxkItvBNynKj2kijqmijo5kPFLnV0q%2F6sDjHNdHGCcbHCAcbarAxVne3Og4mw3BzrO9u5Ax3HvDvI4wocDE1f26cBGNWjhwiFNUOmgjOJq9D2LjZVvWgxr37UYzh38cTnd%2B0MFv59u1RRml77aT2GojsPT0F56cgVp87MO1oGh4tGF7yoFy1RTdeVAZuTq1HVqVfQmZdqkFlu33pQcTbpL7Ra9TZnuUttN9ynTZwf%2BJiXoS8p070DGBRy8KRmZ9TGlDX1KmXoZxbW%2Bfpn%2B%2FQ%2FNPMM3nxEAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fcomponents%2FTopTasks%2FTopTasks.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA52U3W6jMBCF7%2FMUo0orpReuAJO0697kTVYGD8GKsVnbZNld9d0rfoJMElraO5iZ88344OFwwr%2BF5RU6%2BL2Xx2YXwf8NQGFN1T8AKCw8AxJH0Y%2FXDcDbBsCbWS4a4m%2BbwzUsvg%2BLFkBBkzuwpK%2B9koRjLfW5w6L3B1tzyKeBsOuzGc9PR2saLUhulLEMztxuCfkVO0l1TOPy8TXQMO1LkpdSiW3yuA5wXALQlYBiCZCuBIg5oDRntN84%2FH6dppppvmGYWgKsNey0BFhrmJwDvmDY7PDPvaaSmvyRwpcM6D6t26DgZbjECluScScdg113faf8z5s8pU%2BUUhoW8ZuiZBfms0%2Fy%2BbCV2HoiMDeWe2k0A200dhtTGyeHiEXFvTzjTDt050oxaLRD30nyxrrOm9pI7dHO6xnLsDAWe11utEftGTw8zHvxzBnV%2BH4Cb%2Bpxe4NFHh297HuJ8lgG%2B3%2F7obzl2tXcou5n%2FEekFtj2tGk8MVnFIA7iRRCnXfxQoZAcXG4RNXAtYBt85jhNo7od7tpISMdf0cTuXjrJZfCkk0x%2Fuw%2Fxz%2FuXKzqO9BB4ceLSMxnh76sJkpowBgAA%22%7D"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var TopTaskLink = 'q6igu5c';
export var TopTasks = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{color:{purple:{initial:'q6igu55'},blue:{initial:'q6igu56'}}},compoundVariants:[],defaultVariants:{color:'purple'},baseClassName:'q6igu54'});
export var arrowHover = 'q6igu50';
export var arrowHoverOut = 'q6igu51';
export var arrowWhiteHover = 'q6igu52';
export var arrowWhiteHoverOut = 'q6igu53';
export var task = 'q6igu5b';
export var taskBlock = 'q6igu5f';
export var tasksWrapper = 'q6igu5d';
export var variableBlockMobile = 'q6igu5e';
export var variableBlockTask = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{items:{'2':{initial:'q6igu58'},'3':{initial:'q6igu59'},'4':{initial:'q6igu5a'}}},compoundVariants:[],defaultVariants:{},baseClassName:'q6igu57'});