import * as Popover from '@radix-ui/react-popover';

import { Box } from '../Box';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { ButtonUnset } from '../ButtonUnset';

import * as styles from './ComboBox.css';
import { ComboBoxProps } from '.';

type ComboBoxContentProps = {
  id: string;
  filter?: React.ReactNode;
  width?: string;
  value?: { label: string; value: string };
} & Pick<ComboBoxProps, 'options' | 'handleOnChange' | 'noResultsText'>;

export const ComboBoxContent = ({
  id,
  options,
  filter,
  handleOnChange,
  value,
  noResultsText,
  width,
}: ComboBoxContentProps) => {
  return (
    <Box
      style={{ '--available-width': width } as React.CSSProperties}
      id={`${id}-listbox`}
    >
      <Popover.Content
        align="start"
        className={styles.comboBoxContent}
        sideOffset={-2}
      >
        <Box bg="backgroundLight" asChild>
          <Stack gap={4} asChild>
            <div role="listbox" className={styles.listbox}>
              {filter ? filter : null}
              <Box
                style={{ maxHeight: '400px', overflow: 'scroll' }}
                paddingInline={6}
                paddingBottom={6}
              >
                <Stack gap={2}>
                  {options.map((option, index) => (
                    <ButtonUnset
                      key={`${index}-${option}`}
                      onClick={() => handleOnChange(option)}
                    >
                      <Text
                        weight={
                          value?.value === option.value ? 'semiBold' : 'regular'
                        }
                      >
                        {option.label}
                      </Text>
                    </ButtonUnset>
                  ))}
                  {!options.length ? (
                    <Text>{noResultsText ?? 'Geen resultaten gevonden.'}</Text>
                  ) : null}
                </Stack>
              </Box>
            </div>
          </Stack>
        </Box>
      </Popover.Content>
    </Box>
  );
};
