import { PreviewSearchInitialState, usePreviewSearch } from "@sitecore-search/react";
import { useQuery } from "@tanstack/react-query";
import { IntegrationApi } from '@alliander-fe/api';


import { useMemo } from "react";
import { baseQueryParams } from "src/utils";

export const queryParams = {
  ...baseQueryParams,
};

type InitialState = PreviewSearchInitialState<'itemsPerPage' | 'keyphrase' | 'suggestionsList'>;

type ArticleModel = {
  id: string;
  name: string;
  image_url: string;
  url: string;
  source_id?: string;
};

type SuggestionsList = {
  suggestion: string;
  max?: number;
};

type UseSearchProps = { 
  defaultKeyphrase?: string;
  defaultItemsPerPage?: number;
  suggestionsList: SuggestionsList[];
};

export const useSearch = ({ defaultKeyphrase = '', defaultItemsPerPage = 8, suggestionsList }: UseSearchProps) => {
  const {
    widgetRef,
    state: { keyphrase },
    actions: { onItemClick, onKeyphraseChange },
    queryResult: { isFetching: queryResultIsFetching, isSuccess: queryResultIsSuccess, isError: queryResultIsError, data: { suggestion } = {} },
    query,
  } = usePreviewSearch<ArticleModel, InitialState>({
    query: (q) => {
      q.isEnabled = () => false;
    },
    state: {
      keyphrase: defaultKeyphrase,
      itemsPerPage: defaultItemsPerPage,
      suggestionsList,
    },
  });
  
  // This is used to get the suggestion list from the suggestion object.
  const suggestionList = useMemo(() => {
    if(!suggestion) return [];

    // Only show results of the suggestion type that has results.
    // The order is determined by our definition of suggestionsList
    const suggestionOrder = suggestionsList.map((s) => s.suggestion);
    const foundSuggestionType = suggestion
      ? suggestionOrder.find((s) => suggestion?.[s]?.length > 0)
      : null;

    const foundSuggestion = foundSuggestionType ? suggestion?.[foundSuggestionType] || [] : [];

    return foundSuggestion.map((item) => {
      return item.text
    });
  }, [suggestion, suggestionsList]);

  const getSuggestionResults = useQuery({
    queryKey: ['suggestion', suggestionList],
    enabled: !!suggestionList.length,
    queryFn: () => IntegrationApi.searchEndpointsGetSearchEndPoint({searchTerms: suggestionList}),
    select: (data) => {
      return data?.termCounts?.map((d) => {
        return {
          term: d.term,
          count: d.count,
          url: `/zoeken?${queryParams.term}=${d.term}`,
        };
      });
    }
  });

  const isFetching = getSuggestionResults.isLoading || queryResultIsFetching;
  const isError = getSuggestionResults.isError|| queryResultIsError;
  const isSuccess = getSuggestionResults.isSuccess || queryResultIsSuccess;
  const suggestionData = getSuggestionResults.data;

  return {
    widgetRef,
    onItemClick,
    onKeyphraseChange,
    defaultItemsPerPage,
    defaultKeyphrase,
    isError,
    isFetching,
    isSuccess,
    keyphrase,
    query,
    suggestionData,
  };
};

