import { ValidationModels } from '../types/global';

// Extract the Regex from the validation pattern
export const extractRegexFromValidationPattern = (
  pattern: string
): RegExp | undefined => {
  // remove the backslashes which are needed for the sitecode escaping
  const removeSiteCoreEscape = pattern.replace(/\\\\/g, '\\');
  const match = removeSiteCoreEscape.match(/"regularExpression":"(.*?)"/);

  if (match && match.length > 1) {
    return new RegExp(match[1]);
  }
};

// Create a regex for the replacements and replace the values in the string.
export const setReplaceText = (
  string: string | undefined,
  replacements: { [key: string]: string }
): string | undefined => {
  if (!string) return undefined;

  const pattern = new RegExp(
    Object.keys(replacements)
      .map((key) => `\\${key}`)
      .join('|'),
    'g'
  );

  return string.replace(pattern, (match: string) => replacements[match]);
};

// Creates the model which we need to validate the field.
export const getValidationModel = (
  model: ValidationModels[0],
  messageReplaceText?: { [key: string]: string }
) => {
  const regex = extractRegexFromValidationPattern(model.parameters);
  
  const message = messageReplaceText
    ? setReplaceText(model.message, messageReplaceText) ?? ''
    : model.message;

  return regex
    ? {
        message: message,
        value: regex,
      }
    : undefined;
};

// Check the enabled field validation.
export const getEnabledValidation = (
  itemId: string,
  validationDataModels: ValidationModels
) => validationDataModels.find((validation) => validation.itemId === itemId);
