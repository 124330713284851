/**
 * Filter options based on a search query
 * @param options - The options to filter
 * @param filter - The search query
 * @returns The filtered and alphabetically sorted options
 */
export const filterOptions = (
  options: { label: string; value: string }[],
  filter: string
) => {
  return options.filter((option) =>
    option.label.toLowerCase().includes(filter.toLowerCase())
  );
};
