import React from 'react';
import {
  Box,
  GridItem,
  Heading,
  Divider,
  PageGrid,
  Stack,
  StackItem,
  InputTelephone,
  Checkbox,
  ScrollLinkWrapper,
  RichText,
  Grid,
  PostalCodeCheckForm,
  CTABlock,
} from '@ads-core/components';
import {
  houseNumberAdditionPattern,
  houseNumberPattern,
  postalCodePattern,
  requiredFieldPattern,
  telephonePattern,
} from '@alliander-fe/validation';
import { useTrackingContext, useTrackingFormTouch } from '@liander/context';
import { IntegrationApi } from '@alliander-fe/api';
import { SitecoreImage } from '@alliander-fe/jss-utils';
import { FilteredOutagesSMSServiceProps } from 'components/OutagesSMSService';

import { useForm, SubmitHandler } from 'react-hook-form';

import { useMutation } from '@tanstack/react-query';
import { formatC4PostalCode } from 'src/utils/applyC4Rules';
import { isOutageCause } from 'src/utils';
import { extractString } from '../Form/utils/utils';
import { telephoneNumberMasking } from '../Form/utils/telephoneNumberMasking';
import { telephoneC4Rules } from '../Form/utils/telephoneC4Rules';
import { Message } from './_Message';
import { notificationMessage } from './utils/NotificationMessage';

type Inputs = {
  houseNumber: number;
  addition: string;
  postalCode: string;
  telephoneNumber: string;
  save: boolean;
};

export const OutagesSMSServiceView = (props: FilteredOutagesSMSServiceProps) => {
  const { trackWizardSimpleChange } = useTrackingContext();
  const [toggleNotification, setToggleNotification] = React.useState<boolean>();
  const trackingFormName = 'aanmelden sms-berichtenservice storingschecker';

  const getServiceMutation = useMutation({
    mutationFn: IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailabilityDetails,
  });

  const smsServiceFutureMutation = useMutation({
    mutationFn: IntegrationApi.outagesEndpointsSubscribeSmsService,
  });

  const smsServiceOutageMutation = useMutation({
    mutationFn: IntegrationApi.outagesEndpointsSubscribeSmsServiceOutage,
  });

  const getSpecificOutageMutation = useMutation({
    mutationFn: IntegrationApi.outagesEndpointsGetSpecificOutage,
  });

  const { handleSubmit, formState, register, setValue } = useForm({
    mode: 'onTouched',
  });

  useTrackingFormTouch({
    wizardName: 'sms-berichtenservice storingschecker',
    wizardStep: '1',
    fields: formState.touchedFields,
  });

  const isOperational =
    getServiceMutation.data?.electricityNetwork?.isOperational ||
    getServiceMutation.data?.gasNetwork?.isOperational;

  const serviceNotFound =
    getServiceMutation.isError || (getServiceMutation.isSuccess && Boolean(!isOperational));

  const isOutage =
    Boolean(getSpecificOutageMutation?.data?.cause) &&
    isOutageCause(getSpecificOutageMutation?.data?.cause);

  const isLoading =
    getServiceMutation.isPending ||
    smsServiceFutureMutation.isPending ||
    smsServiceOutageMutation.isPending ||
    getSpecificOutageMutation.isPending;

  const error =
    getServiceMutation.isError ||
    smsServiceOutageMutation.isError ||
    smsServiceFutureMutation.isError ||
    getSpecificOutageMutation.isError;

  // We check these both these api's separately because in case of also a future subscription we can
  // return that the Outage is already been solved.
  const futureOutageServiceSubscription = smsServiceFutureMutation.data?.success;
  const currentOutageServiceSubscription = smsServiceOutageMutation.data?.success;

  const nrAlreadyExists = isResponseSuccess({
    serviceOne: smsServiceFutureMutation.data?.nrAlreadyExists,
    serviceTwo: smsServiceOutageMutation.data?.nrAlreadyExists,
  });

  const tooManyAddresses = isResponseSuccess({
    serviceOne: smsServiceFutureMutation.data?.tooManyAddresses,
    serviceTwo: smsServiceOutageMutation.data?.tooManyAddresses,
  });

  const outageNoLongerExists = smsServiceOutageMutation.data?.outageNoLongerExists || !isOutage;

  const notification = notificationMessage({
    serviceNotFound,
    tooManyAddresses,
    error,
    currentOutageServiceSubscription,
    futureOutageServiceSubscription,
    nrAlreadyExists: nrAlreadyExists,
    outageNoLongerExists,
    props,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setToggleNotification(true);
    trackWizardSimpleChange({
      action: 'submit',
      actionDetail: 'bevestigen details sms-berichtenservice storingschecker',
      wizardName: trackingFormName,
      wizardStep: '2',
    });

    // Sets the correct format for the postal code and telephone number.
    const postalCode = data.postalCode.replace(/\s+/g, '');
    const telephoneNumber = data.telephoneNumber.replace('+31 6', '06');

    const result = await getServiceMutation.mutateAsync({
      houseNumber: data.houseNumber,
      addition: data.addition,
      postalCode,
    });

    // Checks if the address in of the user is within the working area of Liander.
    const isOperational =
      result.electricityNetwork?.isOperational || result.gasNetwork?.isOperational;
    const referenceNumber = result.outageNumber;

    if (!isOperational) return;

    if (data.save === true) {
      smsServiceFutureMutation.mutateAsync({ ...data, postalCode, telephoneNumber });
    } else {
      smsServiceFutureMutation.reset();
    }

    // When there's no electricity outage we return, we also reset the value of the api's we don't call.
    if (result.electricityNetwork?.isHealthy || !referenceNumber) {
      smsServiceOutageMutation.reset();
      getSpecificOutageMutation.reset();

      return;
    }

    // We request this api to check if there's an outage.
    const specificOutage = await getSpecificOutageMutation.mutateAsync({
      outageNumber: referenceNumber?.toString(),
    });

    // Only when there's a outage we subscribe to the outage SMS service
    if (specificOutage.cause && isOutageCause(specificOutage.cause)) {
      smsServiceOutageMutation.mutateAsync({
        outageNumber: referenceNumber,
        houseNumber: data.houseNumber,
        addition: data.addition ?? '',
        postalCode,
        telephoneNumber,
      });
    }
  };

  return (
    <ScrollLinkWrapper anchorId="smsdienst">
      <PageGrid>
        <GridItem columnEnd="-1" columnStart="1">
          <CTABlock>
            <CTABlock.Column
              columnStart={'1'}
              columnEnd={{ initial: '-1', md: '8' }}
              variant="main"
              bg="containerPrimary"
            >
              <Stack direction="column" gap={10}>
                <StackItem>
                  {props?.title ? (
                    <Box paddingBottom={{ initial: 3, md: 4 }} asChild>
                      <Heading size="h2" color="onDark">
                        {props.title}
                      </Heading>
                    </Box>
                  ) : null}
                  {props?.text ? <RichText tone="onDark">{props.text}</RichText> : null}
                </StackItem>

                <PostalCodeCheckForm color="none" handleOnSubmit={handleSubmit(onSubmit)}>
                  <Stack gap={4}>
                    <Grid gap={4}>
                      <GridItem columnStart={'1'} columnEnd={{ initial: '-1', md: '7' }}>
                        <Stack isFullWidth>
                          <PostalCodeCheckForm.Input
                            label="Postcode"
                            tone="onDark"
                            placeholder="1234AB"
                            error={extractString(formState?.errors?.postalCode?.message)}
                            {...register('postalCode', {
                              required: requiredFieldPattern('Postcode'),
                              pattern: postalCodePattern,
                            })}
                            onChange={(e) =>
                              setValue('postalCode', formatC4PostalCode(e.target.value))
                            }
                            autoComplete="postal-code"
                          />
                        </Stack>
                      </GridItem>

                      <GridItem columnStart={{ initial: '1', md: '7' }} columnEnd={'-1'}>
                        <Stack isFullWidth asChild>
                          <PostalCodeCheckForm.Addendum
                            label={'Huisnr. en toevoeging'}
                            error={
                              extractString(formState?.errors?.houseNumber?.message) ||
                              extractString(formState?.errors?.addition?.message)
                            }
                            tone="onDark"
                            houseNumber={{
                              label: 'Huisnummer',
                              placeholder: '10',
                              error: extractString(formState?.errors?.houseNumber?.message),
                              ...register('houseNumber', {
                                required: requiredFieldPattern('Huisnummer'),
                                pattern: houseNumberPattern,
                              }),
                            }}
                            addition={{
                              label: 'Toevoeging',
                              placeholder: 'A',
                              ...register('addition', {
                                pattern: houseNumberAdditionPattern,
                              }),
                            }}
                          />
                        </Stack>
                      </GridItem>
                    </Grid>
                    <InputTelephone
                      label="Telefoonnummer"
                      tone="onDark"
                      error={extractString(formState?.errors?.telephoneNumber?.message)}
                      handlePhoneMasking={telephoneNumberMasking}
                      onValueChange={(value, countryCode) =>
                        setValue('telephoneNumber', telephoneC4Rules(value, countryCode))
                      }
                      {...register('telephoneNumber', {
                        required: requiredFieldPattern('Telefoonnummer'),
                        pattern: telephonePattern,
                      })}
                    />
                    <Box paddingTop={1} paddingBottom={1}>
                      <Checkbox
                        label={
                          props.checkboxLabel?.toString() ??
                          'Bewaar mijn gegevens voor toekomstige storingen'
                        }
                        tone="onDark"
                        onCheckedChange={(checked) => setValue('save', checked)}
                        error={formState?.errors?.save?.message as string | undefined}
                        {...register('save')}
                      />
                    </Box>
                    <PostalCodeCheckForm.SubmitButton
                      size="large"
                      tone="onDark"
                      variant="secondary"
                      isLoading={isLoading}
                    >
                      {props?.submitButtonLabel
                        ? props?.submitButtonLabel
                        : 'Controleer uw postcode'}
                    </PostalCodeCheckForm.SubmitButton>
                  </Stack>
                </PostalCodeCheckForm>
              </Stack>
            </CTABlock.Column>
            <CTABlock.Column columnStart={{ initial: '1', md: '8' }} columnEnd={'-1'}>
              <CTABlock.ImageContainer
                overlay={
                  !isLoading && toggleNotification && notification.length ? (
                    <CTABlock.Notification
                      arrowColor="containerPrimary"
                      handleOnClose={() => setToggleNotification(false)}
                    >
                      <Stack gap={8}>
                        {notification.map((message, index) => (
                          <>
                            <Message {...message} key={`${index}-notification-message`} />
                            {index < notification.length - 1 ? <Divider tone="onLight" /> : null}
                          </>
                        ))}
                      </Stack>
                    </CTABlock.Notification>
                  ) : null
                }
              >
                <CTABlock.Image objectFit="cover">
                  <SitecoreImage field={props.image} editable />
                </CTABlock.Image>
              </CTABlock.ImageContainer>
            </CTABlock.Column>
          </CTABlock>
        </GridItem>
      </PageGrid>
    </ScrollLinkWrapper>
  );
};

type IsResponseSuccess = {
  serviceOne?: boolean;
  serviceTwo?: boolean;
};

/**
 * When both are available check both otherwise check one or the other.
 * @param serviceOne boolean
 * @param serviceTwo boolean
 * @returns boolean | undefined
 */
const isResponseSuccess = ({ serviceOne, serviceTwo }: IsResponseSuccess): boolean | undefined => {
  if (serviceOne !== undefined && serviceTwo !== undefined) {
    return serviceOne && serviceTwo;
  }

  return serviceTwo !== undefined ? serviceTwo : serviceOne;
};
