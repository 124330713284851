import React from 'react';
import {
  Box,
  SubpageHeroSideImageSitecore,
  SubpageHeroSitecore,
  TopTasksSitecore,
  Text,
  RichText,
  Heading,
  DictionaryDialog,
} from '@ads-core/components';
import NextLink from 'next/link';

import {
  TextField,
  ImageFieldValue,
  LinkField,
  withDatasourceCheck,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { TaskBaseProps } from '@ads-core/types';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreText, SitecoreImage, renderJssLink } from '@alliander-fe/jss-utils';
import { isCongestionCheck } from 'src/utils';
import { CheckTaskProps, SiteCoreCheckTask } from 'src/components/SiteCoreCheckTask';

type SubpageHeroProps = ComponentProps & {
  fields: {
    checkTask?: CheckTaskProps;
    text: TextField;
    explanation: RichTextField;
    image: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
    sideImage: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
    textLink: LinkField;
    linklist: Array<{
      fields: {
        title: {
          value: string;
        };
        anchor: {
          value: string;
        };
      };
    }>;
    tasks: {
      fields: { link: LinkField; title: TextField; color: { value: TaskBaseProps['color'] } };
    }[];
  };
  params: {
    mode: string;
  };
};

const SubpageHero = (props: SubpageHeroProps) => {
  const { tasks, explanation, image, textLink, text, sideImage, linklist, checkTask } =
    props.fields;

  // @todo remove the linkList because we have created a separate HeroSideImage component
  // We don't want Next/Link dependencies in our ads-core package, so we do basic mapping to relevant components on here on app level
  const linkList = linklist.map((item) => ({
    link: (
      <NextLink href={`#${item.fields.anchor.value}`}>
        <SitecoreText key={item.fields.anchor.value} field={item.fields.title} editable />
      </NextLink>
    ),
    arrowLabel: item.fields.title.value?.toString() ?? '',
    arrowLink: <NextLink href={`#${item.fields.anchor.value}`} />,
  }));

  // Render the experience editor html in the RichText if its available
  const explanationString = explanation.editable || explanation.value;

  const isEditor = !!text.editable;

  // @todo remove the SubpageHeroSideImageSitecore component because we have created a separate HeroSideImage component
  // Render the sideImage if the mode is sideImage
  if (props.params.mode === 'sideImage') {
    return (
      <SubpageHeroSideImageSitecore
        text={isEditor ? <SitecoreText field={text} editable /> : text.value?.toString()}
        explanation={explanationString}
        textLink={renderJssLink(textLink)}
        sideImage={<SitecoreImage field={sideImage} editable />}
        linklist={linkList}
      />
    );
  }

  // We don't want Next/Link dependencies in our ads-core package, so we do basic mapping to relevant components on here on app level
  const toptasks = tasks.map(({ fields: { link, title, color } }) => {
    const taskText = title.value?.toString();
    const linkLabel = taskText || link.value.text || 'Klik hier voor meer informatie';
    const linkField: LinkField = {
      ...link,
      value: {
        ...link.value,
        text: linkLabel,
      },
    };

    return {
      link: renderJssLink(
        linkField,
        <Box position="relative" zIndex="1" as="span">
          {taskText}
        </Box>
      ),
      arrowLabel: linkLabel,
      arrowLink: link.value.href ? (
        <NextLink href={link.value.href} aria-label={linkLabel} />
      ) : undefined,
      color,
      href: link.value.href,
    };
  });

  const color = toptasks[0]?.color.value;

  // We filter out the tasks that don't have a link
  type MappedTopTasks = (typeof toptasks)[number];
  type RequiredTopTasks = Omit<MappedTopTasks, 'link'> & {
    link: NonNullable<MappedTopTasks['link']>;
  };
  const filteredTopTasks = toptasks.filter((t): t is RequiredTopTasks => !!t.link);

  return (
    <SubpageHeroSitecore
      text={
        <Heading
          size="h1"
          align={{
            initial: 'center',
            md: explanationString ? 'start' : 'center',
          }}
        >
          <DictionaryDialog>
            {isEditor ? <SitecoreText field={text} editable /> : text.value?.toString() ?? ''}
          </DictionaryDialog>
        </Heading>
      }
      tasks={
        filteredTopTasks.length || checkTask ? (
          <TopTasksSitecore
            tasks={filteredTopTasks}
            variableBlock={
              checkTask ? <SiteCoreCheckTask {...checkTask} color={color} /> : undefined
            }
            variableBlockPosition={isCongestionCheck(checkTask) ? 'start' : 'end'}
            color={color}
          />
        ) : undefined
      }
      explanation={
        explanationString ? (
          <Text align={{ initial: 'center', md: 'start' }} as="div">
            <RichText>{explanationString}</RichText>
          </Text>
        ) : undefined
      }
      image={<SitecoreImage field={image} editable />}
    />
  );
};

export default withDatasourceCheck()<ComponentProps>(SubpageHero);
