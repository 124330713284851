import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';

import { CloseIcon } from '../../icons';
import { ThemeContainer } from '../ThemeContainer';
import { IconButton } from '../IconButton';
import { Box } from '../Box';

import { RichText } from '../RichText';
import { Stack } from '../Stack';
import { Heading } from '../Heading';
import { ButtonLink } from '../ButtonLink';

import * as styles from './Dialog.css';

export type Controls =
  | { trigger?: React.ReactElement; isOpen?: never }
  | ({ isOpen?: boolean; trigger?: never } & { handleClose: () => void });

type DialogProps = {
  children: React.ReactElement;
  trigger: React.ReactElement;
  isOpen?: boolean;
  handleOpenChange?: (isOpen: boolean) => void;
} & (
  | {
      handleOpenChange?: never;
      isOpen?: never;
    }
  | {
      handleOpenChange: (isOpen: boolean) => void;
      isOpen: boolean;
    }
);

export const Dialog = (props: DialogProps) => {
  const { children, handleOpenChange } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  if (!children) {
    return null;
  }

  return (
    <RadixDialog.Root open={props.isOpen ?? isOpen} onOpenChange={handleOpenChange ?? setIsOpen}>
      {props.trigger ? <RadixDialog.Trigger asChild>{props.trigger}</RadixDialog.Trigger> : null}

      <RadixDialog.Portal>
        <ThemeContainer>
          <RadixDialog.Overlay className={styles.overlay} />
          <RadixDialog.Content className={styles.dialogContent}>
            <RadixDialog.Close
              className={styles.closeButton}
              asChild
              onClick={(event) => event.stopPropagation()}
            >
              <IconButton
                icon={<CloseIcon />}
                label="Sluit"
                tone="onLight"
                shape="brand"
                variant="ghost"
              />
            </RadixDialog.Close>
            <Box className={styles.innerContent} width="100%" height="100%">
              {children}
            </Box>
          </RadixDialog.Content>
        </ThemeContainer>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export type DialogContentProps = {
  tag?: string;
  title?: string | React.ReactElement;
  description?: string;
  button?: {
    href?: string;
    text?: string;
  };
  image?: React.ReactNode;
  video?: React.ReactNode;
};

export const DialogContent = (props: DialogContentProps) => {
  const { tag, title, description, button } = props;

  if (!tag && !title && !description && !button) {
    return null;
  }

  return (
    <Box
      width="100%"
      height="100%"
      paddingTop={{ initial: 10, md: 40 }}
      paddingRight={{ initial: 30, md: 40 }}
      paddingLeft={{ initial: 6, md: 20 }}
      paddingBottom={{ initial: 12, md: 24 }}
    >
      {tag ? (
        <Box paddingBottom={{ initial: 12, md: 10 }} asChild>
          <Box paddingBottom={4}>
            <RichText>{tag}</RichText>
          </Box>
        </Box>
      ) : null}
      <Box paddingBottom={20} asChild>
        <Stack gap={{ initial: 8, md: 10 }}>
          <RadixDialog.Title asChild>
            <Heading size="h2">{props.title}</Heading>
          </RadixDialog.Title>

          <RadixDialog.Description asChild>
            <Stack gap={8}>
              <RichText>{props.description}</RichText>
              {props.image ? props.image : null}
              {props.video ? props.video : null}
              <Box>
                {props.button?.text ? (
                  <ButtonLink asChild href={props.button?.href}>
                    {props.button?.text}
                  </ButtonLink>
                ) : null}
              </Box>
            </Stack>
          </RadixDialog.Description>
        </Stack>
      </Box>
    </Box>
  );
};

