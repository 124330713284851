import React, { cloneElement } from 'react';
import {
  NavLink,
  ButtonLink,
  DesktopHeader,
  MobileHeader,
  IconButton,
  Hidden,
  Box,
} from '@ads-core/components';
import { Segment } from '@alliander-fe/sitecore-types';
import SearchSuggestions from 'src/components/SearchSuggestions';
import { PersonIcon } from '@ads-core/icons';
import { useMedia } from '@ads-core/hooks';
import NextLink from 'next/link';
import { InternalOrExternalLink } from 'src/components/InternalOrExternalLink';
import { useRouter } from 'next/router';
import { mq } from '@ads-core/breakpoints';

type HeaderProps = {
  fields: {
    segments: Segment[];
    labels: {
      back?: string;
      logo?: string;
    };
  };
};

const Header = (props: HeaderProps) => {
  const router = useRouter();
  const isDesktop = useMedia(mq.md);
  const activeMainMenu = props.fields.segments.find((item) => item.isActive);

  const subMenu = props.fields.segments
    .filter((item) => !item.isActive)
    .map((item) => item.link)
    .concat(activeMainMenu ? [activeMainMenu.workingAt, activeMainMenu.my] : []);

  const currentSegmentLink = activeMainMenu?.link.href ? (
    <NextLink href={activeMainMenu?.link.href} />
  ) : (
    <></>
  );

  const logo = cloneElement(
    currentSegmentLink,
    undefined,
    <Box width={{ initial: '98px', md: '104px' }} height={{ initial: 'auto', md: '48px' }} asChild>
      <img src="/assets/logo.png" alt={props.fields.labels.logo ?? 'logo Liander'} />
    </Box>
  );

  const activeMy = activeMainMenu?.my;
  const activeWorking = activeMainMenu?.workingAt;

  if (!activeMainMenu) return null;

  const segmentMenu = props.fields.segments.map((item) => ({
    link: (
      <InternalOrExternalLink
        href={item.link.href}
        aria-current={item.isActive ? 'page' : undefined}
      >
        {item.link.text}
      </InternalOrExternalLink>
    ),
  }));

  const mappedMainMenu = activeMainMenu.mainMenu.map((item) => {
    const mappedSubMenu = item.subMenu.map((subItem) => {
      return {
        subHeader: subItem.subHeader,
        links: subItem.links
          .filter((item) => item.href && item.text)
          .map((link) => {
            const isCurrent = router.asPath === link.href;
            return {
              link: (
                <InternalOrExternalLink
                  href={link.href}
                  target={link.isExternal ? '_blank' : undefined}
                  aria-current={isCurrent ? 'page' : undefined}
                >
                  {link.text}
                </InternalOrExternalLink>
              ),
            };
          }),
      };
    });

    return {
      ...item,
      link: (
        <InternalOrExternalLink
          aria-current={item.link.href === router.asPath ? 'page' : undefined}
          href={item.link.href}
          target={item.link.isExternal ? '_blank' : undefined}
        >
          {item.link.text}
        </InternalOrExternalLink>
      ),
      subMenu: mappedSubMenu,
    };
  });

  return (
    <>
      {isDesktop !== true ? (
        <Hidden above="md">
          <div>
            <MobileHeader
              logo={logo}
              mainMenu={{ segment: activeMainMenu.title, items: activeMainMenu.mainMenu }}
              subMenu={subMenu}
              search={<SearchSuggestions />}
              profile={
                activeMy?.text && activeMy.href ? (
                  <NavLink asChild>
                    <NextLink href={activeMy.href}>
                      <IconButton
                        shape="squircle"
                        icon={<PersonIcon color="onLight" />}
                        label="open profiel"
                        variant="transparant"
                        size="small"
                      />
                    </NextLink>
                  </NavLink>
                ) : null
              }
            />
          </div>
        </Hidden>
      ) : null}

      {isDesktop !== false ? (
        <Hidden below="md">
          <div>
            <DesktopHeader
              logo={logo}
              segmentMenu={segmentMenu}
              mainNav={mappedMainMenu}
              rightTopMenu={
                <>
                  {activeWorking?.href && activeWorking.text ? (
                    <NavLink tone="onDark" asChild>
                      <NextLink href={activeWorking.href}>{activeWorking.text}</NextLink>
                    </NavLink>
                  ) : null}
                  {activeMy?.text && activeMy.href ? (
                    <ButtonLink size="small" tone="onDark" href={activeMy.href}>
                      {activeMy.text}
                    </ButtonLink>
                  ) : null}
                </>
              }
              search={<SearchSuggestions />}
            />
          </div>
        </Hidden>
      ) : null}
    </>
  );
};

export default Header;
