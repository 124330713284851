import React from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

import { ComponentProps } from 'src/jss/lib/component-props';

import { forceArray, baseQueryParams, slugify, createFilterMapper, formatDate } from 'src/utils';
import { Filter } from 'src/types';

import {
  ComboBox,
  GridItem,
  Heading,
  InfoSectionComponent,
  PageGrid,
  Pagination,
  SimpleHero,
  Stack,
  StackItem,
} from '@ads-core/components';

import { useRouter } from 'next/router';
import NextLink from 'next/link';
import NextImage from 'next/image';
import { useQuery } from '@tanstack/react-query';

type NewsOverviewProps = ComponentProps & {
  fields: {
    title: { value: string };
    subtitle: { value: string };
    tagFilter: Filter[];
    regionsFilter: Filter[];
    segmentFilter: Filter[];
  };

  params?: {
    anchor?: string;
  };
};

type NewsArticle = {
  publicationDate: string;
  segment: string;
  tag: string;
  regions: string[];
  title: string;
  image: {
    src: string;
    alt: string;
    width: string;
    height: string;
  } | null;
  itemPath: string;
};

type NewsResponse = {
  totalAmount: number;
  amountOfPages: number;
  newsArticles: NewsArticle[];
};

export const queryParams = {
  ...baseQueryParams,
};

const NewsOverview = (props: NewsOverviewProps) => {
  const router = useRouter();
  const currentPath = forceArray(router.query.path).join('/');

  const { tagFilter, regionsFilter, segmentFilter } = props.fields;

  // Get data from the query params
  const page = router.query[queryParams.page];
  const currentPage = forceArray(page).length > 0 ? Number(forceArray(page)[0]) : 1;

  const region = forceArray(router.query[queryParams.region])[0] || '';
  const segment = forceArray(router.query[queryParams.segment])[0] || '';
  const tag = forceArray(router.query[queryParams.tag])[0] || '';

  // Create mappers for pretty a pretty url
  const regionMapper = createFilterMapper(regionsFilter, 'id');
  const tagMapper = createFilterMapper(tagFilter, 'displayName');
  const segmentMapper = createFilterMapper(segmentFilter, 'id');

  // Filter values
  const regionFilterValue = regionMapper[region] || '';
  const tagFilterValue = tagMapper[tag] || '';
  const segmentFilterValue = segmentMapper[segment] || '';
  const pageFilterValue = Math.max(currentPage - 1, 0);

  // Create the tanstack query to fetch articles
  const query = useQuery({
    queryKey: [regionFilterValue, tagFilterValue, segmentFilterValue, pageFilterValue],
    queryFn: async () => {
      const response = await fetch(
        `/api/get-news-articles?pageNumber=${pageFilterValue}&region=${regionFilterValue}&tag=${tagFilterValue}&segment=${segmentFilterValue}`
      );

      const json: NewsResponse = await response.json();
      return json;
    },
  });

  if (!query.data) return null;

  // Get page count from the data
  const pageCount = query.data.amountOfPages || 1;

  const newsArticles = (query.data.newsArticles || []).map((article) => ({
    publicationDate: formatDate(article.publicationDate),
    tag: article.tag,
    regions: article.regions,
    title: article.title,
    infoImage: article.image ? (
      <NextImage
        src={article.image.src}
        alt={article.image.alt}
        width={Number(article.image.width)}
        height={Number(article.image.height)}
      />
    ) : undefined,
    linkLabel: article.title,
    link: <NextLink href={`${currentPath}/${article.itemPath}`} />,
  }));

  return (
    <>
      <SimpleHero title={props.fields.title.value} text={props.fields.subtitle.value} />
      <PageGrid as="section">
        <GridItem columnEnd="-1" columnStart="1">
          <Stack
            direction={{ initial: 'column', md: 'row' }}
            isFullWidth
            gap={{ initial: 4, md: 10 }}
          >
            <StackItem grow>
              <ComboBox
                placeholder="Kies een segment"
                label="Filter op segment"
                options={[
                  { label: 'Toon alle', value: '' },
                  ...props.fields.segmentFilter.map((item) => {
                    return {
                      label: item.displayName,
                      value: item.id,
                    };
                  }),
                ]}
                value={segmentFilterValue}
                handleOnChange={(option) => {
                  const selectedItem = props.fields.segmentFilter.find(
                    (item) => item.id === option.value
                  );

                  router.push({
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      [queryParams.segment]: selectedItem ? slugify(selectedItem.displayName) : '',
                    },
                  });
                }}
              />
            </StackItem>
            <StackItem grow>
              <ComboBox
                placeholder="Kies een regio"
                label="Filter op regio"
                options={[
                  { label: 'Toon alle', value: '' },
                  ...props.fields.regionsFilter.map((region) => {
                    return {
                      label: region.displayName,
                      value: region.id,
                    };
                  }),
                ]}
                value={regionFilterValue}
                handleOnChange={(option) => {
                  const selectedItem = props.fields.regionsFilter.find(
                    (item) => item.id === option.value
                  );

                  router.push({
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      [queryParams.region]: selectedItem ? slugify(selectedItem.displayName) : '',
                    },
                  });
                }}
              />
            </StackItem>
            <StackItem grow>
              <ComboBox
                placeholder="Kies een tag"
                label="Filter op tag"
                options={[
                  { label: 'Toon alle', value: '' },
                  ...props.fields.tagFilter.map((tag) => {
                    return {
                      label: tag.displayName,
                      value: tag.displayName,
                    };
                  }),
                ]}
                value={tagFilterValue}
                handleOnChange={(option) => {
                  const selectedItem = props.fields.tagFilter.find(
                    (item) => item.displayName === option.value
                  );

                  router.push({
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      [queryParams.tag]: selectedItem ? slugify(selectedItem.displayName) : '',
                    },
                  });
                }}
              />
            </StackItem>
          </Stack>
        </GridItem>
      </PageGrid>
      {query.isLoading && (
        <PageGrid>
          <GridItem columnStart="1" columnEnd="-1">
            <Heading size="h5" as="h3">
              Bezig met laden..
            </Heading>
          </GridItem>
        </PageGrid>
      )}
      {newsArticles.length > 0 ? (
        <InfoSectionComponent articles={newsArticles} />
      ) : (
        <PageGrid>
          <GridItem columnStart="1" columnEnd="-1">
            <Heading size="h5" as="h3">
              Geen resultaten
            </Heading>
          </GridItem>
        </PageGrid>
      )}
      <Pagination
        forcePage={pageCount < 2 ? undefined : currentPage - 1}
        pageCount={pageCount < 2 ? 0 : pageCount}
        onPageChange={(e) => {
          const selectedPage = e.selected + 1;
          router.push({
            pathname: router.pathname,
            query: {
              ...router.query,
              [queryParams.page]: selectedPage,
            },
          });
        }}
      />
    </>
  );
};

export default withDatasourceCheck()<ComponentProps>(NewsOverview);
