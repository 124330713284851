import { ValidationDataModel } from '@sitecore-jss/sitecore-jss-forms';
import { FieldValues, RegisterOptions } from 'react-hook-form';
import { validationModels } from '../utils';

type Field = {
  model: {
    required: boolean;
    title: string;
    minLength?: number;
    maxLength?: number;
    min?: number | string;
    max?: number | string;
    validationDataModels: Array<ValidationDataModel>;
  };
};

type AllowedKeys = Exclude<
  keyof Field['model'],
  'title' | 'validationDataModels'
>;

/**
 * We check if the field has a validation model with the itemId of the CONDITIONALLY_REQUIRED validation model.
 * Or if the field has the required attribute set to true.
 *
 * The CONDITIONALLY_REQUIRED validation model is a custom validation model that is used to make fields conditionally required based on a condition.
 * This needs to be handled in the front-end, because Sitecore can't handle conditional required fields.
 *
 * @param field - The field that needs to be validated
 * @returns boolean
 */
export function isRequiredField(field: Field) {
  return (
    field.model.validationDataModels.some(
      (v) => v.itemId === validationModels.CONDITIONALLY_REQUIRED
    ) || field.model.required
  );
}

const condition = <V extends number | string | boolean>(
  value: V | undefined,
  message: string
) => (value ? { value, message } : undefined);

export const getOptions = (
  /** The field that needs to be validated */
  field: Field,
  /** If you only want a subset of validation keys, you can pass an array of those keys here */
  include?: AllowedKeys[]
): Pick<RegisterOptions<FieldValues, string>, AllowedKeys> => {
  const { min, max, minLength, maxLength } = field.model;

  const required = isRequiredField(field);

  const options = {
    required: condition(
      required,
      `"${field.model.title}" is een verplicht veld`
    ),
    min: condition(min, `Minimale waarde van "${field.model.title}" is ${min}`),
    max: condition(max, `Maximale waarde van "${field.model.title}" is ${max}`),
    minLength: condition(
      minLength,
      `Minimale lengte van "${field.model.title}" is ${minLength}`
    ),
    maxLength: condition(
      maxLength,
      `Maximale lengte van "${field.model.title}" is ${maxLength}`
    ),
  };

  if (include) {
    const returnData = include.reduce(
      (acc, key) => ({ ...acc, [key]: options[key] }),
      {}
    ) as Pick<RegisterOptions<FieldValues, string>, AllowedKeys>;

    return returnData;
  }

  return options;
};
