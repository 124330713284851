
import { Field } from '../types';
import { isButtonField, isSectionField } from "./utils";

/**
 * Overwrite the navigationStep field of the submit button.
 * This way we can go back and forth between steps.
 * @param fields - The fields to overwrite
 * @param step - The step to overwrite
 * @returns The fields with the overwritten navigationStep field
 */
export const overwriteSubmitButton = (fields: Field[], step: number | undefined): Field[] => {
  if (!step) {
    return fields;
  }

  return fields.map((field): Field => {
    if (isButtonField(field)) {
      const overwrittenButtonField = {
        ...field,
        model: {
          ...field.model,
          navigationStep: step,
        },
        navigationStepField: {
          ...field.navigationStepField,
          value: step,
        },
      };
      return overwrittenButtonField;
    }

    if (isSectionField(field)) {
      return { ...field, fields: overwriteSubmitButton(field.fields, step) };
    }

    return field;
  });
};