import {
  FormResult,
  SitecoreForm,
  serializeForm,
} from '@sitecore-jss/sitecore-jss-forms';
import { getButtonField } from "./getButtonField";
import { overwriteSubmitButton } from "./overwriteSubmitButton";
import { isButtonField } from "./utils";
import { decodeNameToSitecoreFormat } from './utils';

type fetchFormProps = {
  action: string;
  form: SitecoreForm;
  formValues: Record<string, unknown>;
  mappedPrefix: string;
  step: number;
};

type FetchFormResult =
  | FormResult
  | { error: [string, { message: string }]; validationErrors?: Record<string, string[]> };

export const fetchFormData = async ({
  action,
  form,
  formValues,
  mappedPrefix,
  step,
}: fetchFormProps): Promise<FetchFormResult> => {
  const goToStep = step < 0 ? -1 : 1;

  // Looks for the submit button
  const allButtonFields = getButtonField(form.fields);
  const submitButtonField = allButtonFields.find(
    (f) => isButtonField(f) && f.model.navigationStep === 1
  );
  const submitButtonName = isButtonField(submitButtonField)
    ? submitButtonField.buttonField.name
    : null;

  // Because we've encoded the data to work with react-hook-form, we need to decode it to Sitecore format
  const mappedData = Object.entries(formValues)
    .filter(([, value]) => value)
    .reduce((acc, [key, value]) => {
      const revertedKey = decodeNameToSitecoreFormat(key);
      return { ...acc, [revertedKey]: value instanceof FileList ? [...value] : value };
    }, {});

  // Overwrites the submit button value so we can skip to the other pages.
  const newForm = {
    ...form,
    fields: overwriteSubmitButton(form.fields, goToStep),
  };

  const formData = serializeForm(newForm, {
    submitButtonName: submitButtonName,
  });


  formData.mergeOverwritingExisting(mappedData);

  try {
    const req = await fetch(action, {
      body: formData.toMultipartFormData(),
      method: 'post',
      credentials: 'include', // IMPORTANT: Sitecore forms relies on cookies for some state management, so credentials must be included.
    });
    const result = (await req.json()) as FormResult;
    const fetchStep = step < 0 ? step + 1 : step - 1;

    // Because sitecore can go back more than 1 step, we need to fetch the data for each step we go forward or backwards.
    if (result.nextForm && fetchStep !== 0) {
      return fetchFormData({
        action,
        form: result.nextForm,
        formValues,
        mappedPrefix,
        step: fetchStep,
      });
    }

    // If the form is successful, we return the result.
    if (result.success) {
      return result;
    }

    return {
      error: [
        `root.${mappedPrefix}`,
        {
          message: JSON.stringify([
            'Er is iets misgegaan bij het versturen van het formulier. Probeer het later opnieuw.',
          ]),
        },
      ],
      validationErrors: result.validationErrors,
    };
  } catch (error) {
    console.error('Error while submitting', error);
    return {
      error: [
        `root.${mappedPrefix}`,
        {
          message: JSON.stringify([
            'Er is iets misgegaan bij het versturen van het formulier. Probeer het later opnieuw.',
          ]),
        },
      ],
    };
  }
};