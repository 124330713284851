import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fglobal%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VW227jNhB971cMYCzQBRyBHFKkxD75%2Blj0DwrJkmwlvsVyHCdF%2F70gG2%2BhIafA2ojgnDM3DXnIyf6UQ6%2BOUgn46xeAp6fH%2F9LBBOdYoP1thKODyTp8xrhyMClQruxyjGsHk5XwnzGeO5gIYcuZGuPGwUSvJerFGLdM%2FIKpp2TwisFrJv4m1Jnn1ozxhqm%2FZeJ3DL5l8B3T%2F56p55mp%2F4WJs2fiHJj3OjJ1nhj8zOCvDH5h8IGp%2F8rYvzH2N8b%2BncHvTJwPxv6TwaXgCMkRXmJd7r%2BE8Brr5EaLjhBeZLKqN%2BWGEDmXw8tsPVvrNZGltMy%2BkMWPhijqU%2FIUJzdZcwVsmMbLhtnh0ktutVguV%2BTIkF5zZiFLUxDCiy5fyDwvCbFjTh%2FZM7KQXnfLcrVYU%2BIleJiiWBHiS3kLS1ty4FrvtWeXc61oVV58q3wtlvQFvfraprNtQwgvvzbvTEcJr7%2Bu63RLN50XYNe0TRTqyrXkjXuPG%2BcRRDgrraS98ipcClsK2qsPB9dLdRzO1aU9Xgn5yd1d4n%2B8kNMjItN%2BVByhma2KObO%2F0HAelvMomGZiybQfK0ZyyN19uOFa0nAdbpmVxI4jtlyoHZe858r1Wpyv56s5zREuwdY2CgkRtIhF0UhCHIKAzEZQD6%2FFRmGH5AzGE7cZvBZnKp6A8NWBON9BCv8s%2FOOyratflZ0CyimoKYhM4PdpsNIys9aa8x2UyjSiTJtLrayVXz6ImVJG%2BRw2K0zJuMgS8eEiMctRmlBWJqRFxufL3GQmF76UPFMoRJG2FoWwtjCPqjJrLJrwC6UqmDcRuUEs5XfStIuD%2Fji0VxDh653T22dIGCa309UFcjjt%2B4aJ9TYySUa5OfC9aqph1zaMzt7HNkn13h3o852AH%2BwFi%2F%2FNHZRSgtFJmO1TycNwn2pAmO5Tp5DiTjrFTR5hwE%2FmsMxZH0b8lLLCjJ9SVhjy66Yp65YQ%2FqSrNk3dEVmHMb%2BrG6zIBBXm%2FE7VbRSqDeolYBdWmIDbxJL6%2Bb6IwN6B8PDjj9DPnv5XnI8HsXhxkItvBNynKj2kijqmijo5kPFLnV0q%2F6sDjHNdHGCcbHCAcbarAxVne3Og4mw3BzrO9u5Ax3HvDvI4wocDE1f26cBGNWjhwiFNUOmgjOJq9D2LjZVvWgxr37UYzh38cTnd%2B0MFv59u1RRml77aT2GojsPT0F56cgVp87MO1oGh4tGF7yoFy1RTdeVAZuTq1HVqVfQmZdqkFlu33pQcTbpL7Ra9TZnuUttN9ynTZwf%2BJiXoS8p070DGBRy8KRmZ9TGlDX1KmXoZxbW%2Bfpn%2B%2FQ%2FNPMM3nxEAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fcomponents%2FFollowUpSteps%2FFollowUpSteps.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA7VXTW%2FiMBC98yusSkhEqlESmtCayx72uMc97G1lYgfcBjtyTAus%2BO%2BrOF92cCAg9UbIfLw382bszE%2Fb44JSH%2FybAEBYkWf4iAAXnK4mAHwxorYIhNG0fNpSttkqBAI%2FP5TPayEJlVCJHIEwPwCCiy0lQEnMixxLylVplYuCKSY4AnhdiGyvdGTtFPvT1eQ8mdcY5vSTcg1ENol6BoIQ%2FT6jqet1si%2BU2FH5UwP5xTjV1jXORGRCIvCJ5QzCv0HBFjxYBMy7EQKtaSokfQbXbHCqqKyS4eRjI8Wek5EJxS7H%2FHgPZHIjggPxhckdgHvpyibVGbR%2FIriiXCHw9GRIptHIgGYkJmxfIBD502GNWCqoJePrX6XCUiF3qPqZYUVnkT99BjDyp15pcoKME3pAIOoLyKD%2BfdBNgd5EDkdADzTgLpmkGVbsUyfbYblhHK4zkXzAQmGpSnQl6M79SseGpv6%2BOlSME5wls4aIi%2ByfWcvTXbmWfdBWDoY2l3YATVL3zV3QjMRdIaxJCHsdcSjXdl9wr6XkeHOetLx%2B4804QO8moMXjXBYP74OXB4BGD%2FjEV%2FTf6tU6DuLhQe8U%2FVJJujnnLIFXijYONkcdrs%2B%2F30l4YWt4OZZOb7opJxfiUWadlmNYV4G%2FnXRg76Al4moLky3LyEwQ4rXnk%2FmiPF08jd611Xwj3CvKcKEqN4N0b5u15m%2F6bSq4ggU7UQSCuCJuLxz3cqtLFYX2Rmye3eWoQlVlAIXI2EUxY13M932hWHqEbbsSyhWV1mpOM6oz4YxtOGSK7grTzjlAoY6uGX%2FVgGPfdzVq5NHU1hI%2FsCjWD2zpZJxPtT1%2F7ChhGBSJpJQDzAmY7RiH7TSFL%2FmhUtbcvPMaRdZSK0tx7oysg%2FOGbVwb5ZgQxjfQtelPXt%2FJ0G7%2FFAUQBPnh0sOC1JvykX3Rhk6tXx74DlhRDWtI%2B80Ff9lE6N0p7FuORW9Z86o9XipUpkFvjyAu1AylTDbbwKsjuDYIDPwawkDEbgG1ARjPGKdNBLxXYnVvfLPHN%2FsTe%2F0%2B3K7BpYg6nPVtRLcPhpEpK3MBXCvIkEjHhRtY1a5xMpbrBek3Wxnxa6OlxqX5x%2FAhtU%2F3vWlUxF856zXi88LIQJ0Zxlz0z5Pzf4jOpKZ%2FDwAA%22%7D"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var cardRecipe = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{hasConclusion:{false:{initial:'zhy3ee8'}}},compoundVariants:[],defaultVariants:{hasConclusion:false},baseClassName:'zhy3ee7'});
export var cardsWrapper = 'zhy3ee1';
export var companyTag = 'zhy3ee3';
export var conclusionBlock = 'zhy3ee6';
export var counter = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{variant:{company:{initial:'zhy3eea'},customer:{initial:'zhy3eeb'},checked:{initial:'zhy3eec'}},index:{odd:{initial:'zhy3eed'},even:{initial:'zhy3eee'}}},compoundVariants:[],defaultVariants:{variant:'company',index:'even'},baseClassName:'zhy3ee9'});
export var customerTag = 'zhy3ee4';
export var dashedLine = 'zhy3ee0';
export var solvedTag = 'zhy3ee5';
export var tag = 'zhy3ee2';