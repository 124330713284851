import React from 'react';
import { Box } from '../Box';
import { GridItem } from '../GridItem';
import { Heading } from '../Heading';
import { PageGrid } from '../PageGrid';
import { mq } from '../../global/breakpoints';
import {
  LinkCardDesktop,
  LinkCardMobile,
  LinkCardWithImageView,
} from '../LinkCard';
import { Stack } from '../Stack';
import { useMedia } from '../../hooks/useMedia';
import { Divider } from '../Divider';
import { CombineProps } from '../../types/type-helpers';
import * as styles from './InfoSection.css';

export const InfoSectionComponent = (props: InfoSectionProps) => (
  <InfoSectionView {...props} />
);

export const InfoSectionSitecore = (props: InfoSectionSitecoreProps) => (
  <InfoSectionView {...props} />
);

export const InfoSectionView = (props: InfoSectionViewProps) => {
  const isDesktop = useMedia(mq.md);
  const [articleWithImage, ...articles] = props.articles;

  const mappedArticles = articles.map((item, i) => {
    return (
      <GridItem
        columnStart={{ initial: '1', md: 'auto' }}
        columnEnd={{ initial: '-1', md: 'span 4' }}
        key={`LinkCardDesktop-${i}`}
      >
        {isDesktop ? (
          <LinkCardDesktop {...item} />
        ) : (
          <Box width="100%">
            <LinkCardMobile {...item} />
            {i !== articles.length - 1 ? (
              <Box
                paddingLeft={{ initial: 6, md: 6 }}
                paddingRight={{ initial: 6, md: 6 }}
              >
                <Divider tone="onLight" />
              </Box>
            ) : null}
          </Box>
        )}
      </GridItem>
    );
  });

  // Checks if the title prop has any value in case of a ReactElement
  const infoSectionTitle =
    typeof props.title === 'string' || props.title?.props.field.value
      ? props.title
      : undefined;

  return (
    <Box className="ads-infoSectionComponent">
      {infoSectionTitle ? (
        <PageGrid>
          <GridItem
            columnStart={{ initial: '1', lg: '3' }}
            columnEnd={{ initial: '-1', lg: '-5' }}
          >
            <Heading size="h2" className={styles.title}>
              {infoSectionTitle}
            </Heading>
          </GridItem>
        </PageGrid>
      ) : null}

      <PageGrid>
        {articleWithImage ? (
          <GridItem
            columnStart={{ initial: '1', md: 'auto' }}
            columnEnd={{ initial: '-1', md: 'span 8' }}
          >
            <LinkCardWithImageView
              tag={articleWithImage.tag}
              title={articleWithImage.title}
              infoImage={articleWithImage.infoImage}
              link={articleWithImage.link}
              publicationDate={articleWithImage.publicationDate}
              regions={articleWithImage.regions}
              linkLabel={articleWithImage.linkLabel}
            />
          </GridItem>
        ) : null}

        {isDesktop ? (
          mappedArticles
        ) : (
          <GridItem
            columnStart={{ initial: '1' }}
            columnEnd={{ initial: '-1' }}
            className={styles.cardsWrapper}
          >
            <Box paddingTop={6} asChild>
              <Stack direction="column" isFullWidth>
                {mappedArticles}
              </Stack>
            </Box>
          </GridItem>
        )}
      </PageGrid>
    </Box>
  );
};

type InfoSectionProps = {
  title?: string;
  articles: {
    tag?: string;
    title?: string | React.ReactElement;
    link?: React.ReactElement;
    linkLabel: string;
    infoImage?: React.ReactElement;
    publicationDate?: string;
    regions?: string[];
  }[];
};

type InfoSectionSitecoreProps = {
  title?: React.ReactElement;
  articles: {
    tag?: React.ReactElement;
    title?: React.ReactElement;
    link?: React.ReactElement;
    linkLabel: string;
    infoImage?: React.ReactElement;
    publicationDate?: React.ReactElement;
    regions?: React.ReactElement[];
  }[];
};

type InfoSectionViewProps = CombineProps<
  InfoSectionProps,
  InfoSectionSitecoreProps
>;
