import React from 'react';
import clsx from 'clsx';
import * as Popover from '@radix-ui/react-popover';

import { Stack } from '../Stack';
import { Text } from '../Text';
import { ButtonUnset } from '../ButtonUnset';
import { Box } from '../Box';
import { ChevronDownIcon, ChevronUpIcon } from '../../icons';

import * as styles from './ComboBox.css';
import { ComboBoxProps } from '.';

type TriggerProps = {
  id: string;
  isOpen: boolean;
  value?: { label: string; value: string };
} & Pick<ComboBoxProps, 'placeholder' | 'label' | 'error' | 'tone'>;

export const ComboBoxTrigger = React.forwardRef<
  HTMLButtonElement,
  TriggerProps
>(({ id, label, value, isOpen, error, tone, placeholder }, ref) => {
  const classNames = styles.listboxToggle({ tone: tone, isOpen: isOpen });

  return (
    <Stack gap={2}>
      <Text size="label">
        <label id={`${id}-label`}>{label}</label>
      </Text>
      <Popover.Trigger asChild>
        <ButtonUnset
          aria-labelledby={`${id}-label`}
          aria-controls={`${id}-listbox`}
          role="combobox"
          ref={ref}
          aria-invalid={error ? true : undefined}
        >
          <Box
            className={clsx(classNames, styles.input, styles.inputSuggestions)}
            paddingBlock={4}
            paddingInline={6}
            asChild
          >
            <Stack direction="row" gap={2} alignX="justify">
              {value ? (
                <Text>{value.label}</Text>
              ) : (
                <Text className={!isOpen ? styles.placeholder : undefined}>
                  {placeholder}
                </Text>
              )}
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Stack>
          </Box>
        </ButtonUnset>
      </Popover.Trigger>
    </Stack>
  );
});
