import React from 'react';

import {
  Box,
  Stack,
  Heading,
  ProgressDetails,
  TextLink,
  Dialog,
  RichText,
} from '@ads-core/components';
import { ChevronRightIcon } from '@ads-core/icons';

import { IntegrationApiFeaturesOutagesContractsDataOutage } from '@alliander-fe/api';
import { Cause, getCause, isOutageCause } from 'src/utils';

import { FilteredOutageHeroProps } from 'components/OutageHero';
import { setReplaceText } from 'src/utils/format';
import { OutageDialogContent } from '../OutageModal';

type OutageHeroProps = {
  title: string;
  modalTitle: string;
} & FilteredOutageHeroProps &
  IntegrationApiFeaturesOutagesContractsDataOutage;

export const OutageHeroProgress = (outageData: OutageHeroProps) => {
  const [isOpen, setDialogIsOpen] = React.useState(false);
  const resolvedOutages = outageData.status?.toLowerCase() === 'opgelost';

  const { energyType, status: apiStatus, cause: apiCause, title, modalTitle, ...rest } = outageData;
  const isMaintenance = !isOutageCause(outageData.cause);

  // We only show the disclaimer text incase of a outage.
  const outageDisclaimerText = !isMaintenance ? rest.outageDetailsModalText : undefined;

  const statusObject = setStatus({
    status: apiStatus,
    cause: getCause(apiCause),
    ...rest,
  });

  const status = statusObject.label;
  const statusDescription = statusObject.description;
  const statusPhases = statusObject.phases;

  const affectedPostalCodes = rest.affectedPostalCodes
    ? setReplaceText(rest.affectedPostalCodes, { ';': ', ' })
    : '';

  const affectedStreets = rest.affectedStreets
    ? setReplaceText(rest.affectedStreets, { ';': ', ' })
    : '';

  if (!outageData || (!status && !statusDescription)) return null;

  return (
    <Box
      bg="containerLight"
      paddingTop={{ initial: 10, md: 14 }}
      paddingBottom={{ initial: 8, md: 10 }}
      paddingLeft={{ initial: 10, md: 16 }}
      paddingRight={{ initial: 10, md: 12 }}
      asChild
    >
      <Stack alignX="start" gap={4} alignY="justify" isFullHeight>
        <Box width="100%">
          {outageData ? (
            <Box paddingBottom={4} asChild>
              <Heading size="h4" as="h3">
                {title}
              </Heading>
            </Box>
          ) : null}
          {statusDescription ? <RichText>{statusDescription}</RichText> : null}
          <ProgressDetails currentStatus={status ?? ''} statusOptions={statusPhases} />
        </Box>

        <Dialog
          isOpen={isOpen}
          handleOpenChange={setDialogIsOpen}
          trigger={
            <TextLink afterIcon={<ChevronRightIcon />} asChild>
              <button>Bekijk alle details</button>
            </TextLink>
          }
        >
          <OutageDialogContent
            handleClose={() => setDialogIsOpen(false)}
            {...rest}
            cause={apiCause}
            status={apiStatus}
            statusDescription={statusDescription}
            statusPhases={statusPhases}
            title={modalTitle}
            outageDetailsText={
              resolvedOutages ? outageData.noOutagesDetailsModalText : outageDisclaimerText
            }
            outageDetailsLinkOne={rest.outageDetailsModalLinkOne}
            outageDetailsLinkTwo={rest.outageDetailsModalLinkTwo}
            affectedPostalCodes={affectedPostalCodes}
            affectedStreets={affectedStreets}
          />
        </Dialog>
      </Stack>
    </Box>
  );
};

type StatusProps = {
  cause?: Cause;
  status?: string | undefined | null;
} & FilteredOutageHeroProps;

const setStatus = ({ cause, status, ...outageProps }: StatusProps) => {
  if (cause === 'Onderhoud') {
    const maintenancePhases = [
      outageProps.maintenancePhaseOneLabel,
      outageProps.maintenancePhaseTwoLabel,
      outageProps.maintenancePhaseThreeLabel,
    ].filter((val): val is string => val !== undefined);

    switch (status) {
      case 'monteur onderweg':
        return {
          label: outageProps.maintenancePhaseOneLabel,
          description: outageProps.maintenancePhaseOneText,
          phases: maintenancePhases,
        };
      case 'monteur ter plaatse':
        return {
          label: outageProps?.maintenancePhaseTwoLabel,
          description: outageProps?.maintenancePhaseTwoText,
          phases: maintenancePhases,
        };
      case 'opgelost':
        return {
          label: outageProps?.maintenancePhaseThreeLabel,
          description: outageProps?.maintenancePhaseThreeText,
          phases: maintenancePhases,
        };
      default:
        return {
          label: outageProps.maintenancePhaseOneLabel,
          description: outageProps.maintenancePhaseOneText,
          phases: maintenancePhases,
        };
    }
  }

  const outagePhases = [
    outageProps?.outagePhaseOneLabel,
    outageProps?.outagePhaseTwoLabel,
    outageProps?.outagePhaseThreeLabel,
  ].filter((val): val is string => val !== undefined);

  switch (status) {
    case 'monteur onderweg':
      return {
        label: outageProps?.outagePhaseOneLabel,
        description: outageProps?.outagePhaseOneText,
        phases: outagePhases,
      };
    case 'monteur ter plaatse':
      return {
        label: outageProps?.outagePhaseTwoLabel,
        description: outageProps?.outagePhaseTwoText,
        phases: outagePhases,
      };
    case 'opgelost':
      return {
        label: outageProps?.outagePhaseThreeLabel,
        description: outageProps?.outagePhaseThreeText,
        phases: outagePhases,
      };
    default:
      return {
        label: outageProps?.outagePhaseOneLabel,
        description: outageProps?.outagePhaseOneText,
        phases: outagePhases,
      };
  }
};
