import clsx from 'clsx';
import { SearchIcon } from '../../icons';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { ToneFocus } from '../ToneFocusProvider';

import * as styles from './ComboBox.css';
import { ComboBoxProps } from '.';

type ComboBoxFilterProps = {
  filterValue: string;
  handleFilterChange: (filter: string) => void;
} & Pick<ComboBoxProps, 'searchFieldLabel' | 'searchFieldPlaceholder'>;

export const CombBoxFilter = ({
  filterValue,
  handleFilterChange,
  searchFieldLabel,
  searchFieldPlaceholder,
}: ComboBoxFilterProps) => {
  return (
    <Box paddingInline={6}>
      <Box position="relative">
        <ToneFocus>
          <Box
            asChild
            width="100%"
            paddingLeft={4}
            paddingRight={16}
            paddingBlock={4}
            className={clsx(styles.input, styles.inputSuggestions)}
            borderRadius="md"
          >
            <input
              aria-label={searchFieldLabel ?? 'Zoeken...'}
              type="text"
              value={filterValue}
              onChange={(e) => handleFilterChange(e.target.value)}
              name="zoeken"
              placeholder={searchFieldPlaceholder ?? 'Zoeken...'}
              autoComplete="off"
            />
          </Box>
        </ToneFocus>
        <Box
          width="40px"
          height="40px"
          className={styles.searchIcon}
          bg="backgroundLight"
          borderRadius={'circle'}
          asChild
        >
          <Stack alignX="center" alignY="center">
            <SearchIcon aria-label="zoeken" size="sm" />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
