import React from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { HomepageHeroSitecore, TopTasksSitecore } from '@ads-core/components';
import type { ImageFieldValue, LinkField, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreText, SitecoreImage, renderJssLink } from '@alliander-fe/jss-utils';
import NextLink from 'next/link';
import { isCongestionCheck } from 'src/utils';
import { Image } from 'src/components/Image';
import {
  FilterValuePropertyFromFields,
  mapWithoutValueProperty,
} from 'src/utils/mapWithoutValueProperty';
import { CheckTaskProps, SiteCoreCheckTask } from 'src/components/SiteCoreCheckTask';

type HomepageHeroApiProps = ComponentProps & {
  fields: {
    image: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
    text: TextField;
    tasks: {
      fields: { link: LinkField; title: TextField };
    }[];
    explanation: TextField;
    textLink: LinkField;
    checkTask?: CheckTaskProps;
  };
};

export type FilteredHomePageHeroProps = FilterValuePropertyFromFields<
  HomepageHeroApiProps['fields']
>;

const HomepageHero = (props: HomepageHeroApiProps) => {
  const mappedFields = mapWithoutValueProperty(props.fields);
  const isEditor = !!props.fields.text.editable;
  const { tasks, text, image, checkTask } = mappedFields;

  // We don't want Next/Link dependencies in our ads-core package, so we do basic mapping to relevant components on here on app level
  const toptasks = tasks
    .map(({ fields: { title, link } }) => ({
      link: renderJssLink({
        ...link,
        value: { ...link.value, text: title.value?.toString() },
      }),
      arrowLabel: title.value?.toString() ?? '',
      arrowLink: link.value.href ? (
        <NextLink href={link.value.href} aria-label={link.value.text} />
      ) : null,
    }))
    .filter(
      (
        t
      ): t is {
        link: React.ReactElement;
        arrowLink: React.ReactElement;
        arrowLabel: string;
      } => !!t.link
    );

  return (
    <HomepageHeroSitecore
      text={isEditor ? <SitecoreText field={props.fields.text} editable /> : text?.toString()}
      tasks={
        <TopTasksSitecore
          tasks={toptasks}
          variableBlock={checkTask ? <SiteCoreCheckTask {...checkTask} /> : undefined}
          variableBlockPosition={isCongestionCheck(checkTask) ? 'start' : 'end'}
        />
      }
      image={
        isEditor ? (
          <SitecoreImage field={image} editable />
        ) : (
          <Image
            src={image.src ?? ''}
            alt={image.alt}
            width={Number(image.width)}
            height={Number(image.height)}
          />
        )
      }
      textLink={renderJssLink(props.fields.textLink)}
      explanation={
        props.fields.explanation.value ? (
          <SitecoreText field={props.fields.explanation} editable />
        ) : undefined
      }
    />
  );
};

export default withDatasourceCheck()<ComponentProps>(HomepageHero);
