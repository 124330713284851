import { Box, ButtonRound, Hidden, Stack, Tag, Text, VisuallyHidden } from '@ads-core/components';
import { CheckmarkIcon, ChevronRightIcon } from '@ads-core/icons';

/**
 * Step indicator component
 * @param currentPage - The current page
 * @param totalAmountOfSteps - The total amount of steps
 * @param handlePageChange - The handle page change function
 * @returns The step indicator component
 */
export const StepIndicator = ({
  currentPage,
  totalAmountOfSteps,
  handlePageChange,
}: {
  currentPage: number;
  totalAmountOfSteps: number;
  handlePageChange: (step: number) => void;
}) => {
  const amountOfSteps = totalAmountOfSteps ? totalAmountOfSteps - 1 : 0;
  const steps = Array.from(Array(amountOfSteps ?? 0).keys());

  return (
    <Stack direction="row" alignX={{ initial: 'start', md: 'center' }} gap={{ initial: 2, lg: 4 }}>
      <Hidden above="md">
        <Box>
          <Tag>
            Stap {currentPage} van {amountOfSteps}
          </Tag>
        </Box>
      </Hidden>

      <Hidden below="md">
        {steps.map((_, index) => (
          <Step
            key={index}
            currentPage={currentPage}
            index={index}
            handlePageChange={handlePageChange}
            amountOfSteps={amountOfSteps}
          />
        ))}
      </Hidden>
    </Stack>
  );
};

type StepProps = {
  currentPage: number;
  index: number;
  handlePageChange: (step: number) => void;
  amountOfSteps: number;
};

const Step = ({ currentPage, index, handlePageChange, amountOfSteps }: StepProps) => {
  const item = index + 1;
  const isLastStep = item === amountOfSteps;

  return (
    <>
      <Hidden below="md">
        <Stack direction="row" alignY="start" gap={{ initial: 2, lg: 4 }}>
          <Box maxWidth="80px" asChild>
            <Stack alignX="center" gap={2}>
              <StepButton
                index={item}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
              <VisuallyHidden>
                <Text size="label" align="center">
                  {currentPage === item ? 'Actief' : null}
                  {currentPage > item ? 'Compleet' : null}
                  {currentPage < item ? 'Volgende' : null}
                </Text>
              </VisuallyHidden>
            </Stack>
          </Box>
          {!isLastStep ? (
            <Box height={{ initial: '32px', md: '48px' }} asChild>
              <Stack alignX="center" alignY="center">
                <ChevronRightIcon size="xs" color="grey" />
              </Stack>
            </Box>
          ) : null}
        </Stack>
      </Hidden>
    </>
  );
};

type StepButtonProps = Pick<StepProps, 'index' | 'handlePageChange' | 'currentPage'>;

const StepButton = ({ index, currentPage, handlePageChange }: StepButtonProps) => {
  const buttonVariant = (index: number) => {
    switch (true) {
      case currentPage === index:
        return 'active';
      case currentPage > index:
        return 'success';
      case currentPage < index:
        return 'ghost';
    }
  };

  return (
    <ButtonRound
      aria-label={`Ga naar pagina ${index}`}
      onClick={() => handlePageChange(index - currentPage)}
      variant={buttonVariant(index)}
      disabled={currentPage + 1 < index || index - currentPage === 0}
      name={`step-${index}`}
    >
      <Text weight="semiBold" as="span">
        {currentPage > index ? <CheckmarkIcon size={{ initial: 'xs', md: 'sm' }} /> : index}
      </Text>
    </ButtonRound>
  );
};
