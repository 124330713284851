import { EditIcon } from '../../../icons/index';
import { Heading } from '../../Heading';
import { Hidden } from '../../Hidden';
import { Stack } from '../../Stack';
import { TextLink } from '../../TextLink';
import { Box } from '../../Box';
import * as styles from '../WizardSimple.css';
import { Question } from '..';

export const WizardSimpleAnsweredStep = ({
  onEditAnswerClick,
  question,
  editAnswerLabel,
}: {
  question: Question;
  onEditAnswerClick(): void;
  editAnswerLabel: string;
}) => {
  const focus = (element: HTMLButtonElement) => element?.focus();

  return (
    <Box padding={4} paddingLeft={0} width="100%">
      <Box position="relative" paddingBottom={12} paddingTop={12}>
        <Stack gap={4}>
          <Hidden below="md">
            <Stack alignX="end" className={styles.editLink}>
              <TextLink
                afterIcon={<EditIcon size="xs" />}
                onClick={() => onEditAnswerClick()}
                variant="secondary"
                asChild
              >
                <button ref={focus}>{editAnswerLabel}</button>
              </TextLink>
            </Stack>
          </Hidden>
          <Box paddingRight={{ initial: 0, md: 4 }} asChild>
            <Stack gap={4}>
              <Heading size="h5" as="h3">
                {question.text}
              </Heading>
              <Stack direction="row" gap={2} wrap>
                <TextLink
                  onClick={() => onEditAnswerClick()}
                  afterIcon={<EditIcon size="xs" />}
                  asChild
                >
                  <button>{question.givenAnswer?.text}</button>
                </TextLink>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

