import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Box } from '../Box';
import { Text } from '../Text';
import { useMedia } from '../../hooks/useMedia';
import { mq } from '../../global/breakpoints';
import { Heading } from '../Heading';
import { Stack } from '../Stack';
import { VisuallyHidden } from '../VisuallyHidden';
import { AnimatedArrowComponent } from '../AnimatedArrow';
import { TextLink } from '../TextLink';
import { PlayIcon } from '../../icons';
import { Video } from '../Video';
import { Dialog, DialogContent } from '../Dialog';

import * as styles from './table.css';

type TableProps = {
  title: string;
  data: {
    fields: Record<string | number | symbol, React.ReactNode>;
    onRowClick?: () => void;
    link?: React.ReactElement;
  }[];
  scrollable?: boolean;
  className?: string;
};

type ThNativeTypes = React.DetailedHTMLProps<
  React.ThHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
>;

type CreateColumnProps<T> = T extends infer V
  ? {
      dataKey: V;
      title: string;
    } & ThNativeTypes
  : never;

type CreateTableProps<Data extends TableProps['data']> = Pick<
  TableProps,
  'scrollable' | 'className'
> & {
  data: Data;
  columns: CreateColumnProps<keyof Data[0]['fields']>[];
  title: string;
};

export function Table<Data extends TableProps['data']>({
  data,
  columns,
  scrollable,
  title,
  className = '',
}: CreateTableProps<Data>) {
  const isDesktop = useMedia(mq.md);
  const [tabIndex, setTabIndex] = useState<number | undefined>(undefined);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

  // check if the table is scrollable to show a helpfull text for the user
  useEffect(() => {
    const table = tableRef.current;
    const container = containerRef.current;
    const isScrollable =
      table?.scrollWidth !== undefined &&
      container?.scrollWidth !== undefined &&
      table.scrollWidth > container?.clientWidth;
    setTabIndex(isScrollable ? 0 : undefined);
  }, [data, columns, scrollable]);

  if (isDesktop) {
    return (
      <Stack direction="column" gap={10} as="article">
        <Stack isFullWidth className={clsx(scrollable && styles.tableContainerScrollable)} asChild>
          <article ref={containerRef}>
            <table
              tabIndex={tabIndex}
              ref={tableRef}
              className={clsx(!scrollable && styles.tableContainer, className)}
            >
              <Box asChild>
                <Heading size="h2" align="start" asChild>
                  <caption>
                    <VisuallyHidden>{title}</VisuallyHidden>
                  </caption>
                </Heading>
              </Box>
              <thead>
                <tr>
                  {columns.map(({ title, dataKey, className = '', ...elementProps }) => {
                    return (
                      <Box
                        asChild
                        paddingBottom={7}
                        paddingRight={{ initial: 2, md: 7 }}
                        key={String(dataKey)}
                      >
                        <Heading
                          size={{ initial: 'description', md: 'paragraph' }}
                          isResponsive={false}
                          fontWeight={'semiBold'}
                          asChild
                        >
                          <th className={clsx(styles.heading, className)} {...elementProps}>
                            {title}
                          </th>
                        </Heading>
                      </Box>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={clsx(styles.tableRow, {
                        [styles.clickableRow]: item.link,
                      })}
                      onClick={() => {
                        item.onRowClick?.();
                      }}
                      onMouseEnter={() => setHoveredRowIndex(index)}
                      onMouseLeave={() => setHoveredRowIndex(null)}
                    >
                      {columns.map((column) => (
                        <Box
                          asChild
                          paddingTop={{ initial: 2, md: 7 }}
                          paddingBottom={{ initial: 2, md: 7 }}
                          paddingRight={{ initial: 2, md: 7 }}
                          key={String(column.dataKey)}
                        >
                          <td
                            className={clsx(
                              scrollable && styles.tableDescriptionScrollable,
                              styles.tableDescription
                            )}
                          >
                            {item.fields[column.dataKey]}
                          </td>
                        </Box>
                      ))}

                      {item.link ? (
                        <td width="60">
                          <Stack asChild alignX="end" alignY="center">
                            <Box zIndex="1">
                              <AnimatedArrowComponent
                                size="large"
                                active={index === hoveredRowIndex}
                                link={item.link}
                                label={'Lees meer informatie'}
                              />
                              <VisuallyHidden>Lees meer informatie</VisuallyHidden>
                            </Box>
                          </Stack>
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </article>
        </Stack>
        {tabIndex !== undefined && (
          <Text size="paragraph" align="end">
            Scroll naar rechts voor meer informatie
          </Text>
        )}
      </Stack>
    );
  }

  return (
    <Stack className={clsx(styles.tableContainer)} direction="column">
      {data.map((item, index) => {
        return (
          <Box paddingTop={4} paddingBottom={4} position="relative" asChild key={index}>
            <div className={styles.tableRow}>
              <dl
                onClick={() => {
                  item.onRowClick?.();
                }}
                onMouseEnter={() => setHoveredRowIndex(index)}
                onMouseLeave={() => setHoveredRowIndex(null)}
              >
                {columns
                  .filter((column) => item.fields[column.dataKey])
                  .map((column, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Heading
                          size={{ initial: 'description', md: 'paragraph' }}
                          fontWeight={'semiBold'}
                          isResponsive={false}
                          asChild
                        >
                          <Box paddingBottom={1} asChild>
                            <dt className={styles.descriptionsTerm}>{column.title}</dt>
                          </Box>
                        </Heading>
                        <Box paddingBottom={4} asChild zIndex="2">
                          <dd className={styles.description}>{item.fields[column.dataKey]}</dd>
                        </Box>
                      </React.Fragment>
                    );
                  })}
              </dl>
              {item.link ? (
                <Stack asChild alignX={'start'} alignY={'end'}>
                  <Box paddingTop={4}>
                    <Box width="32px" height="32px" position="relative">
                      <AnimatedArrowComponent
                        size="medium"
                        active={index === hoveredRowIndex}
                        link={item.link}
                        label="Lees meer informatie"
                      />
                      <VisuallyHidden>Lees meer informatie</VisuallyHidden>
                    </Box>
                  </Box>
                </Stack>
              ) : null}
            </div>
          </Box>
        );
      })}
    </Stack>
  );
}

type TableImageProps = {
  title: string;
  src: string;
  alt: string;
};

export function TableImage(props: TableImageProps) {
  return (
    <Box asChild borderRadius="md" overflow="hidden" zIndex="3">
      <Dialog
        trigger={
          <button className={styles.imageTrigger}>
            <Box
              width={{ initial: '48px', md: '64px' }}
              height={{ initial: '48px', md: '64px' }}
              className={styles.imageWrapper}
              zIndex="2"
            >
              <Box asChild className={styles.image}>
                <img src={props.src} alt={props.alt} />
              </Box>
            </Box>
          </button>
        }
      >
        <DialogContent
          title={props.title}
          image={
            <Box asChild className={styles.image}>
              <img src={props.src} alt={props.alt} />
            </Box>
          }
        />
      </Dialog>
    </Box>
  );
}

type TableVideoProps = {
  url: string;
  children: string;
  title: string;
  description: string;
};

export function TableVideo(props: TableVideoProps) {
  return (
    <Box zIndex="3">
      <Dialog
        trigger={
          <TextLink afterIcon={<PlayIcon />} asChild>
            <button>{props.children}</button>
          </TextLink>
        }
      >
        <DialogContent
          title={props.title}
          description={props.description}
          video={<Video url={props.url} />}
        />
      </Dialog>
    </Box>
  );
}

