import React from 'react';
import type { TextField, ImageFieldValue, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Box, InfoSectionSitecore, ScrollLinkWrapper } from '@ads-core/components';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreImage, SitecoreText, renderJssLink } from '@alliander-fe/jss-utils';
import NextLink from 'next/link';
import { useSitecoreContext } from '@liander/context';

type GeneralArticle = {
  fields: {
    tag: TextField;
    shortTitle: TextField;
    button: LinkField;

    infoImage: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
  };
};

type NewsArticle = {
  fields: {
    highlightButton: LinkField;
    highlightCardText: TextField;
    highlightCardTitle: TextField;
    highlightTitle: TextField;
    highlightCardButton: LinkField;
    highlightCardImage: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
    seoType: LinkField;
    seoImage: LinkField;
    seoMetaDescription: LinkField;
    seoMetaKeywords: LinkField;
    seoMetaTitle: LinkField;
    pin: { value: boolean };
    pageButton: LinkField;
    body: TextField;
    imageText: TextField;
    introductionText: TextField;
    publicationDate: TextField;
    infoImage: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
    shortTitle: TextField;
    tag: TextField;
  };
  name: string;
  url: string;
};

type InfoSectionSitecoreProps = ComponentProps & {
  fields: {
    title: TextField;
    articles: Array<GeneralArticle | NewsArticle>;
  };
  params?: {
    anchor?: string;
  };
};

const InfoSection = (props: InfoSectionSitecoreProps) => {
  const { isEditor } = useSitecoreContext();

  const siteCoreArticles = props.fields.articles
    .map((article) => {
      const buttonTitle = article.fields.shortTitle.value?.toString();
      const linkLabel = buttonTitle || 'Klik hier voor meer informatie';
      const button: LinkField = isArticle(article)
        ? {
            ...article.fields.button,
            value: {
              ...article.fields.button.value,
              text: buttonTitle,
            },
          }
        : {
            value: {
              href: article.url,
              text: buttonTitle,
            },
          };

      return {
        title: renderJssLink(
          button,
          <Box position="relative" zIndex="1" as="span">
            {buttonTitle}
          </Box>
        ),
        linkLabel: linkLabel,
        tag:
          isEditor || article.fields.tag.value ? (
            <SitecoreText field={article.fields.tag} editable tag="span" />
          ) : undefined,
        infoImage: <SitecoreImage field={article.fields.infoImage} editable />,
        link: button.value.href ? (
          <NextLink href={button.value.href} aria-label={button.value.text} />
        ) : undefined,
      };
    })
    .filter((article) => article.link && article.title);

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <InfoSectionSitecore
        title={<SitecoreText field={props.fields.title} editable />}
        articles={siteCoreArticles}
      />
    </ScrollLinkWrapper>
  );
};

export function isArticle(article?: GeneralArticle | NewsArticle): article is GeneralArticle {
  return typeof article !== 'undefined' && 'button' in article.fields;
}

export default withDatasourceCheck()<ComponentProps>(InfoSection);
