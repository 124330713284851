import React from 'react';
import { InputBase, InputBaseProps } from '../InputBase';

export type InputTextProps = Omit<InputBaseProps, 'type'>;

export const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  (props, ref) => {
    return <InputBase type="text" {...props} ref={ref} />;
  }
);
