import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fglobal%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VW227jNhB971cMYCzQBRyBHFKkxD75%2Blj0DwrJkmwlvsVyHCdF%2F70gG2%2BhIafA2ojgnDM3DXnIyf6UQ6%2BOUgn46xeAp6fH%2F9LBBOdYoP1thKODyTp8xrhyMClQruxyjGsHk5XwnzGeO5gIYcuZGuPGwUSvJerFGLdM%2FIKpp2TwisFrJv4m1Jnn1ozxhqm%2FZeJ3DL5l8B3T%2F56p55mp%2F4WJs2fiHJj3OjJ1nhj8zOCvDH5h8IGp%2F8rYvzH2N8b%2BncHvTJwPxv6TwaXgCMkRXmJd7r%2BE8Brr5EaLjhBeZLKqN%2BWGEDmXw8tsPVvrNZGltMy%2BkMWPhijqU%2FIUJzdZcwVsmMbLhtnh0ktutVguV%2BTIkF5zZiFLUxDCiy5fyDwvCbFjTh%2FZM7KQXnfLcrVYU%2BIleJiiWBHiS3kLS1ty4FrvtWeXc61oVV58q3wtlvQFvfraprNtQwgvvzbvTEcJr7%2Bu63RLN50XYNe0TRTqyrXkjXuPG%2BcRRDgrraS98ipcClsK2qsPB9dLdRzO1aU9Xgn5yd1d4n%2B8kNMjItN%2BVByhma2KObO%2F0HAelvMomGZiybQfK0ZyyN19uOFa0nAdbpmVxI4jtlyoHZe858r1Wpyv56s5zREuwdY2CgkRtIhF0UhCHIKAzEZQD6%2FFRmGH5AzGE7cZvBZnKp6A8NWBON9BCv8s%2FOOyratflZ0CyimoKYhM4PdpsNIys9aa8x2UyjSiTJtLrayVXz6ImVJG%2BRw2K0zJuMgS8eEiMctRmlBWJqRFxufL3GQmF76UPFMoRJG2FoWwtjCPqjJrLJrwC6UqmDcRuUEs5XfStIuD%2Fji0VxDh653T22dIGCa309UFcjjt%2B4aJ9TYySUa5OfC9aqph1zaMzt7HNkn13h3o852AH%2BwFi%2F%2FNHZRSgtFJmO1TycNwn2pAmO5Tp5DiTjrFTR5hwE%2FmsMxZH0b8lLLCjJ9SVhjy66Yp65YQ%2FqSrNk3dEVmHMb%2BrG6zIBBXm%2FE7VbRSqDeolYBdWmIDbxJL6%2Bb6IwN6B8PDjj9DPnv5XnI8HsXhxkItvBNynKj2kijqmijo5kPFLnV0q%2F6sDjHNdHGCcbHCAcbarAxVne3Og4mw3BzrO9u5Ax3HvDvI4wocDE1f26cBGNWjhwiFNUOmgjOJq9D2LjZVvWgxr37UYzh38cTnd%2B0MFv59u1RRml77aT2GojsPT0F56cgVp87MO1oGh4tGF7yoFy1RTdeVAZuTq1HVqVfQmZdqkFlu33pQcTbpL7Ra9TZnuUttN9ynTZwf%2BJiXoS8p070DGBRy8KRmZ9TGlDX1KmXoZxbW%2Bfpn%2B%2FQ%2FNPMM3nxEAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fcomponents%2FAnimatedArrow%2FAnimatedArrow.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA72VXa%2BiMBCG7%2FkVc7OJJmL40HMUk43%2F5KTSQbpCy7ZF3Wz87xvwCyqIsO7eUTo88%2FbtzLDe4a9IkhQVfLk%2FMF5mwoffFkAkRVo%2BACQY6QDsufNtZQGcLAAtalu3nZO1fuTNmnntOPc5b96XZz%2FnfTTz3KHnXZo8LbIW%2B8qd5zTSSGuFdXi36UfrcC5spDUaZ550emU4ZUAmFNNM8ADIRokk17iy6h9pSbiKhEyD82NCNI4KfZNS5XhVhX6W0FAkQgawJ3Jk21%2BuYj53fXdc0OqFQThLyTn9vch8x0kVIFFoM26LXNcyLKoZGI9RMn0Ht3M%2FOrhewIUeTUPBtRRJgnQcxGKPEr7DLcadgAX3pVeJnpJQsz3WokulGxLutlLknNrNthxKXzZCUpQBOK%2BJuhveLcm4nAZv%2FCZvapb20rTooWnRpmn2TJNriqJ%2FXXebjvrAgXUXDq27%2B9l62Enb7Fy%2B94pxAq%2BLwjZRpNcdR%2Bd%2BKhvFloSyXN2Mv48xiQkp0hdvC81RIg4BxIxS5KvGfiynWkYkcn2bdxeYGQzO1FdFUJTg0VaxZHxn9uy2qxQ7J8K8NhHc7AhKJIw2wCpp4%2F%2BU1qunZe1pvSG8pOQdGNVxAL6XHQtGjGwb6%2Bu6Ep1Wo2dOPfq8PlnrFCkjMMokRiiVLZHmIVI7FdeKKdbjEmXMylrVcsHx%2Bns15ld33L%2BY5O%2FI2n9Wd2elL8bh207Rf0S%2BI%2BuAIdiS9lakKpSIHAinMEoZty%2Bl%2FfmxyI5GhfIL0OgVs1uMU4n6V9eeMbvmFVGu480eVGWDVP18o6pZEWuokoNUqTeqWnqPqvQgVXkPVX8ALxr2RmUOAAA%3D%22%7D"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var AnimatedArrow = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{size:{medium:{initial:'_1jeh9pol',sm:'_1jeh9pon',md:'_1jeh9pop',lg:'_1jeh9por',xl:'_1jeh9pot'},large:{initial:'_1jeh9pom',sm:'_1jeh9poo',md:'_1jeh9poq',lg:'_1jeh9pos',xl:'_1jeh9pou'}}},variantClassNames:{color:{onLight:{initial:'_1jeh9pog'},onDark:{initial:'_1jeh9poh'},ghostOnDark:{initial:'_1jeh9poi'}},direction:{leftToRight:{initial:'_1jeh9poj'},topToBottom:{initial:'_1jeh9pok'}}},compoundVariants:[],defaultVariants:{direction:'leftToRight',size:'medium',color:'onLight'},baseClassName:'_1jeh9pof'});
export var arrowWrap = '_1jeh9po2';
export var flyBase = '_1jeh9po0';
export var flyIn = '_1jeh9po7';
export var flyInAnimation = '_1jeh9po5';
export var flyInAnimationHover = '_1jeh9po3';
export var flyInTopToBottom = '_1jeh9pod';
export var flyInTopToBottomAnimation = '_1jeh9pob';
export var flyInTopToBottomAnimationHover = '_1jeh9po9';
export var flyOut = '_1jeh9po8';
export var flyOutAnimation = '_1jeh9po6';
export var flyOutAnimationHover = '_1jeh9po4';
export var flyOutTopToBottom = '_1jeh9poe';
export var flyOutTopToBottomAnimation = '_1jeh9poc';
export var flyOutTopToBottomAnimationHover = '_1jeh9poa';
export var innerWrap = '_1jeh9po1';