import { Box } from '@ads-core/components';
import { IntegrationApi } from '@alliander-fe/api';
import {
  ComponentParams,
  ComponentRendering,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useSitecoreContext } from '@liander/context';

import { enrichPlaceholdersComponentData } from 'src/utils/enrichPlaceholdersComponentData';

export interface CongestionReportsDetailContainerProps {
  rendering: ComponentRendering;
  params?: ComponentParams;
}

const CongestionReportsDetailContainer = (props: CongestionReportsDetailContainerProps) => {
  const { isEditor } = useSitecoreContext();
  const router = useRouter();
  const name = router.query.path ? router.query.path[router.query.path.length - 1] : '';
  const congestionType = router.query.path ? router.query.path[router.query.path.length - 2] : '';

  const {
    isLoading,
    isError,
    data: congestionReport,
  } = useQuery({
    queryKey: ['congestionReport', congestionType, name],
    retry: false,
    enabled: Boolean(congestionType) && Boolean(name),
    queryFn: () =>
      IntegrationApi.congestionReportsEndpointsGetCongestionReportsDetails({
        name: name,
        congestionType: congestionType,
      }),
  });

  // Maps the and enrich them with the data from the api.
  const enrichedComponents = props.rendering.placeholders
    ? enrichPlaceholdersComponentData(
        props.rendering.placeholders['congestion-reports-detail-container-content'],
        {
          isLoading: { value: isLoading },
          isError: { value: isError },
          congestionReport: congestionReport ? { value: { ...congestionReport } } : undefined,
        }
      )
    : [];

  return (
    <Box padding={isEditor ? 4 : 0} style={isEditor ? { border: '1px dotted black' } : undefined}>
      <Placeholder
        name="congestion-reports-detail-container-content"
        rendering={{
          ...props.rendering,
          placeholders: {
            ...props.rendering.placeholders,
            'congestion-reports-detail-container-content': enrichedComponents,
          },
        }}
      />
    </Box>
  );
};

export default CongestionReportsDetailContainer;
