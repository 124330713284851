import { FlagIcon, NoElectricalConnectionIcon, NoGasConnectionIcon } from '@ads-core/icons';
import {
  Box,
  Heading,
  Stack,
  SubpageHeroSitecore,
  TextLink,
  Text,
  TopTasksSitecore,
  StackItem,
} from '@ads-core/components';
import { outageEndDateAndTime, outageFormatTexts, isOutageCause } from 'src/utils';
import { IntegrationApiFeaturesOutagesContractsDataOutage } from '@alliander-fe/api';
import { PolygonMap } from '../PolygonMap';
import { OutageHeroProgress } from './_ViewProgress';
import { OutageHeroFollowupSteps } from './_ViewFollowUpSteps';
import { Props } from '.';

type OutageViewProps = {
  isLoading: boolean;
  postalCode?: string;
  outageData: IntegrationApiFeaturesOutagesContractsDataOutage;
  isSuccess: boolean;
} & Props;

export function OutageView(props: OutageViewProps) {
  const { outageData, isSuccess, isLoading, postalCode = '', ...rest } = props;

  const resolvedOutages = outageData.status?.toLowerCase() === 'opgelost';
  const isMaintenance = !isOutageCause(outageData.cause);
  const texts = getOutageStrings(props, outageData, !!postalCode);
  const data = {
    type: outageData.energyType?.toLowerCase() || '',
    places: outageData.affectedPlaces || '',
    postalCode: postalCode,
  };

  const title = outageFormatTexts(texts.title || '', data);
  const detailTitle = outageFormatTexts(texts.detailTitle || '', data);
  const modalTitle = outageFormatTexts(texts.modalTitle || '', data);

  const topTasks = isSuccess ? props.topTasks : props.noOutageTopTasks;

  const endDateAndTime = outageEndDateAndTime({
    ...outageData,
    resolvedOutages,
  });

  // TODO: Should we render a Skeleton if isLoading is true?
  return (
    <>
      <SubpageHeroSitecore
        text={
          <Stack direction="column" alignY="end" gap={1} isFullHeight>
            <Heading size="h1">{title}</Heading>
          </Stack>
        }
        tasks={
          topTasks ? (
            <TopTasksSitecore
              tasks={topTasks}
              variableBlock={
                outageData.outageNumber ? (
                  <OutageHeroProgress
                    {...rest}
                    {...outageData}
                    title={detailTitle}
                    modalTitle={modalTitle}
                  />
                ) : undefined
              }
              variableBlockPosition={'start'}
              variableBlockSpan={2}
            />
          ) : undefined
        }
        explanation={
          <Box paddingTop={{ initial: 4, md: 0 }} asChild>
            <Stack alignY="end" gap={2}>
              {isSuccess ? (
                <>
                  <Stack direction="row" gap={2}>
                    <StackItem>
                      {outageData.energyType === 'Stroom' ? (
                        <NoElectricalConnectionIcon size="xs" />
                      ) : (
                        <NoGasConnectionIcon size="xs" />
                      )}
                    </StackItem>
                    <Stack direction="column" gap={1} wrap>
                      <Text weight="semiBold">
                        {isMaintenance ? 'Start werkzaamheden:' : 'Storing gemeld:'}
                      </Text>
                      <Text>{outageData.reportTime}</Text>
                    </Stack>
                  </Stack>
                  {endDateAndTime ? (
                    <Stack direction="row" gap={2}>
                      <StackItem>
                        <FlagIcon size="xs" />
                      </StackItem>
                      <Stack direction="column" gap={1} wrap>
                        <Text weight="semiBold">{endDateAndTime.label}</Text>
                        <Text>{endDateAndTime.description}</Text>
                      </Stack>
                    </Stack>
                  ) : null}
                </>
              ) : null}
              {!outageData.outageNumber && props.noOutageLink?.href ? (
                <Stack direction="row" gap={2}>
                  <NoElectricalConnectionIcon size="xs" />
                  <TextLink href={props.noOutageLink.href}>{props.noOutageLink.text}</TextLink>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        }
        image={
          <Stack isFullHeight isFullWidth>
            <PolygonMap
              postalCode={postalCode}
              rings={outageData.rings ?? undefined}
              isLoading={isLoading}
            />
          </Stack>
        }
      />
      {outageData.energyType && (
        <OutageHeroFollowupSteps energyType={outageData.energyType} {...props} />
      )}
    </>
  );
}

/**
 * We handle multiple scenarios that require different texts to be displayed. Scenarios are determined by the cause and status of the outage.
 * @param texts The props that contain the texts
 * @param outage The outage data (probably from the API)
 * @param isSpecific Whether this is a specific outage or a general outage
 * @returns The correct set of texts based on cause and status of the outage.
 */
function getOutageStrings(
  texts: Props,
  outage: IntegrationApiFeaturesOutagesContractsDataOutage,
  isSpecific: boolean
) {
  const isMaintenance = !isOutageCause(outage?.cause);
  const hasOutage = Boolean(!isMaintenance && outage.outageNumber);
  const noOutage = Boolean(!outage.outageNumber);
  const isResolved = outage.status === 'opgelost';

  switch (true) {
    case noOutage && isSpecific:
      return {
        title: texts.specificNoOutageHeroTitle,
        detailTitle: '',
        modalTitle: '',
      };
    case isSpecific && hasOutage:
      return {
        title: texts.specificActiveOutageHeroTitle,
        detailTitle: texts.specificActiveOutageDetailTitle,
        modalTitle: texts.specificActiveOutageModalTitle,
      };
    case isSpecific && isMaintenance:
      return {
        title: texts.specificActiveMaintenanceHeroTitle,
        detailTitle: texts.specificActiveMaintenanceDetailTitle,
        modalTitle: texts.specificActiveMaintenanceModalTitle,
      };

    // This is where isSpecific is false, and thus is a general outage
    case isResolved && isMaintenance:
      return {
        title: texts.generalResolvedMaintenanceHeroTitle,
        detailTitle: texts.generalResolvedMaintenanceDetailTitle,
        modalTitle: texts.generalResolvedMaintenanceModalTitle,
      };
    case isResolved:
      return {
        title: texts.generalResolvedOutageHeroTitle,
        detailTitle: texts.generalResolvedOutageDetailTitle,
        modalTitle: texts.generalResolvedOutageModalTitle,
      };
    case isMaintenance:
      return {
        title: texts.generalActiveMaintenanceHeroTitle,
        detailTitle: texts.generalActiveMaintenanceDetailTitle,
        modalTitle: texts.generalActiveMaintenanceModalTitle,
      };
    default:
      return {
        title: texts.generalActiveOutageHeroTitle,
        detailTitle: texts.generalActiveOutageDetailTitle,
        modalTitle: texts.generalActiveOutageModalTitle,
      };
  }
}
