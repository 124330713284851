import { Headers } from '@ads-core/types';
import { CheckedState } from '@radix-ui/react-checkbox';
import { ButtonFormField, FormFieldSection } from '@sitecore-jss/sitecore-jss-forms';
import { serviceNumbers } from '@ads-core/data';
import { SimpleRecord } from '../types';

// Get the name and replace all dots with underscores and replace brackets [ ] with { }
export function encodeNameToReactHookFormFormat(name: string) {
  return name.replace(/\./g, '_').replace(/\[/g, '{').replace(/\]/g, '}');
}

// Revert it so we can send it to Sitecore
export function decodeNameToSitecoreFormat(name: string) {
  return name.replace(/_/g, '.').replace(/{/g, '[').replace(/}/g, ']');
}

export function extractString(value: unknown) {
  return typeof value === 'string' ? value : undefined;
}

export const getLabel = (name: string, isRequired: boolean) =>
  isRequired ? name : `${name} (niet verplicht)`;

/** Type guards */
export function isHeadingSize(size: string): size is Headers {
  return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(size);
}

export function isButtonField(field: any): field is ButtonFormField {
  return field && 'buttonField' in field;
}

export function isSectionField(field: any): field is FormFieldSection {
  return field && 'fields' in field;
}

export function isCheckedState(value: any): value is CheckedState {
  return value === true || value === false || value === 'indeterminate';
}

export function stringify(value: any) {
  return value !== undefined && value !== null && typeof value !== 'object'
    ? value.toString()
    : value;
}

export const stringifyObject = (obj: SimpleRecord) => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, stringify(value)]));
};


export const isTelephoneServiceNumber = (value: string) => {
  return Boolean(serviceNumbers.find((code) => value.slice(0, code.length) === code));
};
