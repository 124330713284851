import React from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  IntegrationApi,
  IntegrationApiFeaturesOutagesContractsDataOutage as OutageProps,
} from '@alliander-fe/api';
import { ButtonUnset, DialogContent, Dialog, Loader } from '@ads-core/components';
import { FilteredOutagesOnLocationProps } from 'components/OutagesOnLocation';

import { isOutageCause, outageFormatTexts, rewriteEnergyType } from 'src/utils';

import { OutageDialogContent, OutageDialogContentProps } from '../OutageModal';
import OutageBlock from './_OutageBlock';

type OutageBlockComponentProps = OutageProps &
  Pick<
    FilteredOutagesOnLocationProps,
    | 'maintenanceDetailsModalTitle'
    | 'outageDetailsModalTitle'
    | 'outageDetailsModalText'
    | 'outageDetailsModalLinkOne'
    | 'outageDetailsModalLinkTwo'
  >;

type OutageBlockComponentVersionProps =
  | ({ resolvedOutages?: boolean; allResolvedOutages?: never } & OutageBlockComponentProps)
  | ({ resolvedOutages?: never; allResolvedOutages?: boolean } & OutageBlockComponentProps);

export const OutageBlockComponent = ({
  maintenanceDetailsModalTitle,
  outageDetailsModalTitle,
  outageDetailsModalText,
  outageDetailsModalLinkOne,
  outageDetailsModalLinkTwo,
  resolvedOutages,
  allResolvedOutages,
  ...outage
}: OutageBlockComponentVersionProps) => {
  const [outageDialogData, setOutageDialogData] =
    React.useState<Omit<OutageDialogContentProps, 'handleClose'>>();
  const isResolved = resolvedOutages;
  const isAllResolvedOutages = allResolvedOutages;
  const unresolvedOutage = !isResolved && !isAllResolvedOutages;

  const specificOutageMutation = useMutation({
    mutationFn: IntegrationApi.outagesEndpointsGetSpecificOutage,
  });

  const handleOpenDialog = async (props: OutageProps) => {
    if (!props.outageNumber) return;
    const resolvedOutage = !unresolvedOutage;

    const outageModalData = await specificOutageMutation.mutateAsync({
      outageNumber: props.outageNumber.toString(),
    });

    const hasData = Boolean(outageModalData && outageModalData.outageNumber);
    const modalOutageData = hasData ? outageModalData : props;

    const isMaintenance = !isOutageCause(modalOutageData.cause);
    const modalTitleText = isMaintenance
      ? maintenanceDetailsModalTitle?.toString()
      : outageDetailsModalTitle?.toString();

    // In case of Resoled outages show outageDetailsText.
    // In case of unResolved outages only show outageDetailsText on Outages.
    const detailsText = outageDetailsModalText?.toString() ?? '';
    const outageMessage = !isMaintenance ? detailsText : undefined;
    const outageDetailsText = resolvedOutage ? detailsText : outageMessage;

    const transformedEnergyType = rewriteEnergyType(modalOutageData.energyType);

    const outageData = {
      type: transformedEnergyType || '',
      places: modalOutageData.affectedPlaces || '',
      postalCode: modalOutageData.affectedPostalCodes || '',
    };

    const modalTitle = outageFormatTexts(modalTitleText || '', outageData);

    const dialogData: Omit<OutageDialogContentProps, 'handleClose'> = {
      ...modalOutageData,
      title: modalTitle,
      affectedStreets: modalOutageData.affectedStreets?.replace(/;/g, ', ') || '',
      affectedPostalCodes: modalOutageData.affectedPostalCodes?.replace(/;/g, ', ') || '',
      energyType: transformedEnergyType,
      outageDetailsText: outageDetailsText,
      outageDetailsLinkOne: outageDetailsModalLinkOne,
      outageDetailsLinkTwo: outageDetailsModalLinkTwo,
    };

    if (hasData) {
      dialogData.statusDescription = '';
      dialogData.postalCode = '';
    }

    setOutageDialogData(dialogData);
  };

  return (
    <Dialog
      isOpen={!!outageDialogData}
      handleOpenChange={(isOpen) => {
        if (!isOpen) {
          setOutageDialogData(undefined);
        }
      }}
      trigger={
        <ButtonUnset onClick={() => handleOpenDialog(outage)} style={{ width: '100%' }}>
          <OutageBlock.Container>
            {isAllResolvedOutages ? <OutageBlock.AllResolved {...outage} /> : null}
            {isResolved ? <OutageBlock.Resolved {...outage} /> : null}
            {unresolvedOutage ? <OutageBlock.Current {...outage} /> : null}
          </OutageBlock.Container>
        </ButtonUnset>
      }
    >
      {specificOutageMutation.isPending ? (
        <DialogContent
          title={
            <>
              Loading... <Loader />
            </>
          }
        />
      ) : (
        <OutageDialogContent
          {...outageDialogData}
          handleClose={() => setOutageDialogData(undefined)}
        />
      )}
    </Dialog>
  );
};

