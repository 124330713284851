import { CountryCodesProps } from "@ads-core/types";
import { countryCodes, serviceNumbers } from "@ads-core/data";
import { isTelephoneServiceNumber } from "./utils";


/**
 * Apply the correct masking to a the telephone number.
 * @param value - The value to mask
 * @param countryCode - The country code
 * @returns The masked value
 */
export const telephoneNumberMasking = (
  value: string,
  countryCode: CountryCodesProps | undefined
): string => {
  const cleanString = value.replace(/[-\s+]/g, '');
  const isMobile = cleanString.startsWith('06') || cleanString.startsWith('6');
  const isServiceNumber = isTelephoneServiceNumber(cleanString);
  
  if(isServiceNumber) {
    return getPhoneNumberCode({inputValue: cleanString, phoneNumberCodes: serviceNumbers});
  }

  if (isMobile) {
    return getPhoneNumberCode({inputValue: cleanString, phoneNumberCodes: ['06', '6']});
  }

  if (!countryCode) {
    return getPhoneNumberCode({inputValue: cleanString, phoneNumberCodes: countryCodes, prefix: '+'});
  }

  return getPhoneNumberCode({inputValue: cleanString, phoneNumberCodes: countryCode.areaCodes});
};

/**
 * Format the phone number
 * @param countryCode - The country code
 * @param inputValue - The input value
 * @returns The formatted phone number
 */
const phoneNumberFormat = (countryCode: string, inputValue: string) => {
  const cleanString = inputValue.slice(countryCode.length, inputValue.length);
  return cleanString.replace(/([a-z0-9]{3})/gi, '$1 ').trim();
}

type PhoneNumberCodesProps = {
  inputValue: string;
  phoneNumberCodes?: string[];
  prefix?: string;
}

/**
 * Get the phone number code
 * @param inputValue - The input value
 * @param phoneNumberCodes - The phone number codes
 * @param prefix - The prefix
 * @returns The phone number code
 */
const getPhoneNumberCode = ({inputValue, phoneNumberCodes, prefix}: PhoneNumberCodesProps) => {
  const code = phoneNumberCodes?.find((code) => inputValue.slice(0, code.length) === code);

  if(!code) return phoneNumberFormat('', inputValue);

  const formattedValue = phoneNumberFormat(code, inputValue);

  if(prefix) return `${prefix}${code} ${formattedValue}`;

  return `${code} ${formattedValue}`;
}
