import React from 'react';

import { FilteredOutagesOnLocationProps } from 'components/OutagesOnLocation';
import { LocationOutages } from '../LocationOutages';
import { OutageFilter } from '../OutageFilter';

export const SitecoreOutageOnLocation = (props: FilteredOutagesOnLocationProps) => {
  const showRecentResolved = Boolean(props.onlyShowRecentlyResolvedOutages);
  const showAllResolved = Boolean(props.onlyShowAllResolvedOutages);

  const locationOutages = showRecentResolved || (!showRecentResolved && !showAllResolved);
  return locationOutages ? <LocationOutages {...props} /> : <OutageFilter {...props} />;
};
