import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import { isElement } from 'react-is';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { InternalComponentProps } from '../Slot';
import { ToneFocus, ToneVariants } from '../ToneFocusProvider';
import { buttonRound, ButtonVariants } from './ButtonRound.css';

export type ButtonProps = InternalComponentProps & {
  children: React.ReactNode;
  asChild?: boolean;
} & ButtonVariants &
  ButtonHTMLAttributes<HTMLButtonElement> &
  ToneVariants;

export const ButtonRound = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      tone = 'onLight',
      size = 'large',
      variant = 'primary',
      onClick,
      type = 'button',
      className = '',
      style = {},
      asChild,
      ...rest
    },
    ref
  ) => {
    if (asChild && isElement(children)) {
      return (
        <ToneFocus tone={tone}>
          <Slot
            style={style}
            className={clsx(buttonRound({ size, variant }), className)}
            ref={ref}
            {...rest}
          >
            {children}
          </Slot>
        </ToneFocus>
      );
    }

    return (
      <ToneFocus tone={tone}>
        <button
          type={type}
          style={style}
          className={clsx(buttonRound({ size, variant }), className)}
          ref={ref}
          onClick={(e) => {
            if (onClick) onClick(e);
          }}
          aria-disabled={rest.disabled}
          {...rest}
        >
          {children}
        </button>
      </ToneFocus>
    );
  }
);

