import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import * as Accordion from '@radix-ui/react-accordion';
import { Link as LinkType, MainMenu } from '@alliander-fe/sitecore-types';
import { Heading } from '../Heading';
import { NavLink } from '../NavLink';
import { ChevronDownIcon, CloseIcon, MenuIcon } from '../../icons/index';
import { Stack } from '../Stack';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Divider } from '../Divider';
import { useAdsContext } from '../../providers';
import { ToneFocus } from '../ToneFocusProvider';
import * as styles from './MobileHeader.css';

type TopHeaderProps = {
  open: boolean;
  onMenuClick(): void;
  isSticky: boolean;
  search: React.ReactNode;
  profile?: React.ReactNode;
  logo: React.ReactElement;
};

const TopHeader = React.forwardRef<HTMLDivElement, TopHeaderProps>(
  ({ open, onMenuClick, isSticky = false, search, profile, logo }, ref) => {
    const contentRef = React.useRef<HTMLButtonElement>(null);
    React.useEffect(() => {
      if (open && contentRef.current) {
        contentRef.current.focus();
      }
    }, [open]);

    return (
      <div
        className={styles.outerRoot}
        ref={ref}
        style={{
          position: isSticky ? 'fixed' : 'relative',
        }}
      >
        <Box bg="backgroundLight" borderRadius="md" padding={3} asChild>
          <div className={styles.innerRoot}>
            <Stack direction="row" alignX="justify">
              <ToneFocus>{logo}</ToneFocus>
              <Stack direction="row" gap={2} alignY="center">
                {search}
                {profile}
                <Dialog.Trigger asChild onClick={() => onMenuClick()}>
                  <IconButton
                    ref={contentRef}
                    shape="squircle"
                    icon={open ? <CloseIcon /> : <MenuIcon />}
                    label={open ? 'Sluit menu' : 'Open menu'}
                    variant="transparant"
                    size="small"
                    className={styles.headerLinkButton}
                  />
                </Dialog.Trigger>
              </Stack>
            </Stack>
          </div>
        </Box>
      </div>
    );
  }
);

type MobileHeaderProps = {
  mainMenu: { segment: string; items: MainMenu };
  subMenu: LinkType[];
  logo: React.ReactElement;
  search: React.ReactNode;
  profile?: React.ReactNode;
};

export const MobileHeader = (props: MobileHeaderProps) => {
  const topHeaderRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [isSticky, setIsSticky] = React.useState(false);
  const [topHeaderHeight, setTopHeaderHeight] = React.useState(0);

  const adsContext = useAdsContext();
  const offset = 8;

  React.useEffect(() => {
    const topHeaderHeight = (topHeaderRef.current?.clientHeight || 0) + offset;
    setTopHeaderHeight(topHeaderHeight);
    adsContext.setTopHeaderHeight(topHeaderHeight);
  }, [adsContext]);

  React.useEffect(() => {
    const data = topHeaderRef.current?.getBoundingClientRect();
    if (!data) return;

    const handleScroll = () => {
      setIsSticky(
        window.scrollY >= adsContext.headerHeight - topHeaderHeight - offset
      );
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [adsContext, topHeaderHeight]);

  return (
    <div style={{ paddingTop: isSticky ? topHeaderHeight : 0 }}>
      <Box>
        <Dialog.Root modal={false} open={open}>
          <header className={styles.header}>
            {!open && (
              <TopHeader
                logo={props.logo}
                isSticky={isSticky}
                open={open}
                onMenuClick={() => setOpen(!open)}
                ref={topHeaderRef}
                search={props.search}
                profile={props.profile}
              />
            )}
            <div
              style={{
                display: open ? 'block' : 'none',
              }}
            >
              <Dialog.Content
                className={styles.content}
                forceMount
                onEscapeKeyDown={() => setOpen(false)}
              >
                <div style={{ paddingTop: topHeaderHeight }}>
                  <TopHeader
                    open={open}
                    onMenuClick={() => setOpen(!open)}
                    isSticky
                    logo={props.logo}
                    search={props.search}
                    profile={props.profile}
                  />
                  <nav>
                    <Box
                      paddingTop={10}
                      paddingBottom={6}
                      paddingLeft={6}
                      paddingRight={6}
                    >
                      <Dialog.Title asChild>
                        <Heading size="h6" as="h4" color="onDark">
                          {props.mainMenu.segment}
                        </Heading>
                      </Dialog.Title>
                    </Box>
                    <Dialog.Description asChild>
                      <Accordion.Root type="single" collapsible>
                        {props.mainMenu.items.map((item, i) => {
                          return (
                            <Accordion.Item
                              value={item.title}
                              className={styles.accordionItem}
                              key={`${item.title}-${i}`}
                            >
                              {i !== 0 ? (
                                <Box paddingLeft={6} paddingRight={6}>
                                  <Divider tone="onDark" />
                                </Box>
                              ) : null}
                              <ToneFocus tone="onDark">
                                <Accordion.Trigger
                                  className={styles.accordionTrigger}
                                >
                                  <Box
                                    paddingLeft={6}
                                    paddingRight={6}
                                    paddingBottom={4}
                                    paddingTop={4}
                                  >
                                    <Stack alignX="justify" direction="row">
                                      <Heading size="h5" as="h3" color="onDark">
                                        {item.title}
                                      </Heading>
                                      <ChevronDownIcon
                                        color="onDark"
                                        className={styles.accordionTriggerIcon}
                                      />
                                    </Stack>
                                  </Box>
                                </Accordion.Trigger>
                              </ToneFocus>
                              <Accordion.Content
                                forceMount
                                className={styles.accordionContent}
                              >
                                {item.subMenu.map((subItem, index) => {
                                  return (
                                    <Box
                                      paddingBottom={4}
                                      paddingTop={6}
                                      paddingLeft={6}
                                      key={`${subItem.subHeader}-${index}`}
                                    >
                                      <Heading size="h6" color="onDark" asChild>
                                        <h4 id={subItem.subHeader}>
                                          {subItem.subHeader}
                                        </h4>
                                      </Heading>
                                      <Stack asChild gap={6}>
                                        <Box
                                          paddingLeft={6}
                                          paddingTop={8}
                                          asChild
                                        >
                                          <ul
                                            aria-describedby={subItem.subHeader}
                                          >
                                            {subItem.links
                                              .filter(
                                                (item) => item.href && item.text
                                              )
                                              .map((link, index) => {
                                                return (
                                                  <li
                                                    key={`${link.text}-${index}`}
                                                  >
                                                    <NavLink
                                                      tone="onDark"
                                                      href={link.href}
                                                    >
                                                      {link.text}
                                                    </NavLink>
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        </Box>
                                      </Stack>
                                    </Box>
                                  );
                                })}
                              </Accordion.Content>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion.Root>
                    </Dialog.Description>
                  </nav>
                </div>
                <Box
                  paddingLeft={6}
                  paddingRight={6}
                  paddingTop={8}
                  paddingBottom={8}
                >
                  <Stack as="nav" gap={5}>
                    {props.subMenu.map((item, index) => {
                      return (
                        <NavLink
                          href={item.href ?? '#'}
                          tone="onDark"
                          key={`${item.text}-${index}`}
                        >
                          {item.text}
                        </NavLink>
                      );
                    })}
                  </Stack>
                </Box>
              </Dialog.Content>
            </div>
          </header>
        </Dialog.Root>
      </Box>
    </div>
  );
};
