import { useRouter } from 'next/router';
import { baseQueryParams } from 'src/utils';
import { Box, Text, Stack, Loader } from '@ads-core/components';
import { ActionProp, ItemClickedAction } from '@sitecore-search/react';
import { SearchInputSuggestions } from 'src/components/SearchInput';
import React, { ChangeEvent } from 'react';
import { Results } from './_Results';

export const queryParams = {
  ...baseQueryParams,
};

export type SuggestionData = {
  term?: string;
  count?: number;
  url?: string;
};

type PreviewSearchProps = {
  keyphrase: string;
  onKeyphraseChange: (e?: ChangeEvent<HTMLInputElement> | KeyboardEvent | undefined) => void;
  onItemClick: ActionProp<ItemClickedAction>;
  isFetching: boolean;
  isSuccess: boolean;
  isError?: boolean;
  suggestionData: SuggestionData[];
};

export const PreviewSearch = React.forwardRef<HTMLDivElement, PreviewSearchProps>(
  (
    { keyphrase, onKeyphraseChange, onItemClick, isFetching, isSuccess, isError, suggestionData },
    ref
  ) => {
    const router = useRouter();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // We use the previewSearch input name to get the value from the form event, because keyphrase takes too long to update and users press enter immediately
      // @ts-expect-error we cant type Target properly
      router.push(`/zoeken?${queryParams.term}=${e.target.previewSearch.value}`);
    };

    return (
      <Box padding={8} asChild>
        <Stack direction="column" gap={6}>
          <Box>
            <SearchInputSuggestions
              name="previewSearch"
              onChange={onKeyphraseChange}
              aria-label="zoek"
              onSubmit={handleSubmit}
            />
          </Box>

          {isFetching && keyphrase ? (
            <Box>
              Laden... <Loader size="xs" />
            </Box>
          ) : null}

          {!isFetching && keyphrase && (
            <Box ref={ref} aria-live="polite">
              {suggestionData.length > 0 && isSuccess && keyphrase && (
                <Results results={suggestionData} keyphrase={keyphrase} onItemClick={onItemClick} />
              )}

              {suggestionData.length === 0 && isSuccess && keyphrase && (
                <Text size="description">Geen resultaten gevonden</Text>
              )}

              {isError && keyphrase && (
                <Text size="description">
                  Er is een fout opgetreden bij het laden van de suggesties.
                </Text>
              )}
            </Box>
          )}
        </Stack>
      </Box>
    );
  }
);

PreviewSearch.displayName = 'PreviewSearch';
