import React from 'react';
import { CloseIcon } from '@ads-core/icons';
import { Accordion, Box, Heading, TextLink } from '@ads-core/components';
import { postalCodePattern } from '@alliander-fe/validation';
import { useParams } from 'src/hooks/useParams';
import { useRouter } from 'next/router';
import { baseQueryParams } from 'src/utils';
import { FilterAddress } from './_FilterAddress';
import { FilterMinMaxDate } from './_FilterMinMaxDate';
import { FilterEnergyType } from './_FilterEnergyType';
import { minMaxDateValidator } from './utils/minMaxDateValidator';
import { dateRangeValidator } from './utils/dateRangeValidator';

type FilterInputProps = {
  postalCode?: string;
  houseNumber?: string;
  houseNumberAddition?: string;
  startDate?: string;
  endDate?: string;
  type?: string[];
};

const filterIds = {
  address: 'Zoek op locatie',
  date: 'Filter op Datum',
  type: 'Type',
} as const;

export const Filters = ({ handleOpenChange }: { handleOpenChange?: (isOpen: boolean) => void }) => {
  const urlParams = useParams();
  const router = useRouter();
  const [activeAccordionItems, setAccordionActiveItems] = React.useState<string[]>([
    filterIds.address,
    filterIds.date,
    filterIds.type,
  ]);

  const path = router.query.path || [];
  const location = path[path.length - 1];
  const locationIsPostalCode = path ? postalCodePattern.value.test(location) : false;

  const activeFilters = amountOfActiveFilters(urlParams);

  const updateFilters = (props: FilterInputProps) => {
    const pathname = router.asPath.split('?')[0];
    const postalCode = !locationIsPostalCode ? props.postalCode || urlParams.postalCode : undefined;
    const initialFilters = {
      houseNumber: urlParams.houseNumber,
      houseNumberAddition: urlParams.houseNumberAddition,
      startDate: urlParams.startDate,
      endDate: urlParams.endDate,
      type: urlParams.type ? urlParams.type.split(',') : undefined,
    };

    const dateParams = createUrlDateParams({
      newFilters: props,
      currentFilters: urlParams,
    });

    // Sets the postalCode in the url when the url is a postalCode instead of a city.
    const basePath = pathname.split('/').slice(0, -1).join('/');
    const pathName =
      locationIsPostalCode && props.postalCode ? `${basePath}/${props.postalCode}` : pathname;

    if (handleOpenChange) {
      handleOpenChange(false);
    }

    router.push(
      {
        pathname: pathName,
        search: mapFiltersToUrlParams({
          ...initialFilters,
          ...props,
          ...dateParams,
          postalCode: postalCode,
        }),
      },
      undefined,
      { scroll: false }
    );
  };

  const clearFilters = () => {
    const pathname = router.asPath.split('?')[0];

    if (handleOpenChange) {
      handleOpenChange(false);
    }

    if (pathname !== undefined) {
      router.push(
        {
          pathname: pathname,
        },
        undefined,
        { scroll: false }
      );
    }
  };

  const availableFilters = [
    {
      id: filterIds.address,
      title: 'Zoek op locatie',
      content: (
        <FilterAddress
          onChangeValue={(address) => updateFilters({ ...address })}
          value={{
            ...urlParams,
            postalCode: locationIsPostalCode ? location : urlParams.postalCode,
          }}
        />
      ),
    },
    {
      id: filterIds.date,
      title: 'Filter op Datum',
      content: (
        <FilterMinMaxDate
          onChangeValue={(dates) => updateFilters(dates)}
          value={{
            endDate: urlParams.endDate,
            startDate: urlParams.startDate,
          }}
        />
      ),
    },
    {
      id: filterIds.type,
      title: 'Type',
      content: (
        <FilterEnergyType
          onChangeValue={(type) => {
            const activeType = type.length ? type : undefined;

            updateFilters({ type: activeType });
          }}
          value={urlParams?.type ? urlParams?.type.split(',') : undefined}
        />
      ),
    },
  ];

  return (
    <>
      {activeFilters ? (
        <Box paddingBottom={8}>
          <TextLink
            onClick={() => {
              clearFilters();
            }}
            afterIcon={<CloseIcon />}
            asChild
          >
            <button>Wis alle filters ({activeFilters})</button>
          </TextLink>
        </Box>
      ) : null}

      <Accordion
        type="multiple"
        onAccordionOpen={setAccordionActiveItems}
        activeIds={activeAccordionItems}
      >
        {availableFilters.map((filter, i) => {
          const isActive = activeAccordionItems?.includes(filter.id);

          return (
            <Accordion.Container firstChild={i === 0} key={`${i}-${filter.id}`}>
              <Accordion.Item
                {...filter}
                title={
                  <Heading fontWeight="semiBold" size="paragraph" as="h3" isResponsive={false}>
                    {filter.title}
                  </Heading>
                }
                content={<Box paddingTop={4}>{filter.content}</Box>}
                isActive={isActive}
                icon={
                  <Accordion.Icon
                    variant="transparant"
                    tone="onLight"
                    size="small"
                    label={filter.id}
                    isActive={isActive}
                  />
                }
              />
            </Accordion.Container>
          );
        })}
      </Accordion>
    </>
  );
};

export const mapFiltersToUrlParams = (props: FilterInputProps) => {
  const params = new URLSearchParams();

  (Object.entries(props) as [keyof FilterInputProps, unknown][]).forEach(([key, value]) => {
    const paramKey = baseQueryParams[key];

    if (paramKey && value) {
      params.set(paramKey, value.toString());
    }
  });

  return params.toString();
};

const createUrlDateParams = ({
  newFilters,
  currentFilters,
}: {
  newFilters: FilterInputProps;
  currentFilters?: Record<keyof typeof baseQueryParams, '' | string>;
}) => {
  if (newFilters.endDate || newFilters.startDate) {
    const isValidStartDate = newFilters.startDate
      ? minMaxDateValidator(newFilters.startDate)
      : true;
    const isValidEndDate = newFilters.endDate ? minMaxDateValidator(newFilters.endDate) : true;

    const setDates = {
      startDate: isValidStartDate ? newFilters.startDate : currentFilters?.startDate,
      endDate: isValidEndDate ? newFilters.endDate : currentFilters?.endDate,
    };

    const isValidDateRange = dateRangeValidator({
      startDate: setDates.startDate,
      endDate: setDates.endDate,
    });

    return isValidDateRange
      ? setDates
      : {
          startDate: currentFilters?.startDate,
          endDate: currentFilters?.endDate,
        };
  }
};

const amountOfActiveFilters = (
  filters: Record<keyof typeof baseQueryParams, '' | string>
): number | undefined => {
  if (!filters) return undefined;

  const relevantFilters = [filters.postalCode, filters.endDate, filters.startDate, filters.type];
  return relevantFilters.filter(Boolean).length;
};
