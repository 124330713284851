import { FormTracker, SitecoreForm } from '@sitecore-jss/sitecore-jss-forms';
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { Box, Stack } from '@ads-core/components';
import { useTrackingContext, useTrackingFormTouch } from '@liander/context';
import { FormErrors } from '../FormErrors';
import { MapperKeys, mapper } from './mappers/fieldMapper';
import { handleValidationErrors } from './FormHandlers';

import { isButtonField, encodeNameToReactHookFormFormat } from './utils/utils';
import { FormConditionsProvider } from './providers/FormConditionsProvider';
import { Field } from './types';
import {
  flattenNestedFields,
  getValueFields,
  createNameToFieldKeyMap,
  createMappedFieldConditions,
} from './FieldMapping';
import { createInitialValues, createInitialActions } from './FormInitialization';
import { fetchFormData } from './utils/fetchFormData';
import { StepIndicator } from './fields/StepIndicator';

export type SubmitStatus = 'loading' | 'success' | 'nextForm' | 'done' | 'error';
export type FormProps = {
  form: SitecoreFormWithTotalAmountOfSteps;
  totalAmountOfSteps?: number;
  language?: string;
  handleOnSubmit: (status: SubmitStatus) => void;
};

export type SitecoreFormWithTotalAmountOfSteps = SitecoreForm & {
  totalAmountOfSteps?: number;
} & SitecoreForm;

export const Form = ({ form: initialForm, language, handleOnSubmit }: FormProps) => {
  const router = useRouter();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [nextForm, setNextForm] = React.useState<SitecoreFormWithTotalAmountOfSteps | null>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const sitecoreApiKey = process.env.NEXT_PUBLIC_SITECORE_API_KEY;
  const endpoint = `/api/jss/fieldtracking/register?sc_apikey=${sitecoreApiKey}`;
  const tracker = React.useMemo(() => new FormTracker({ endpoint }), [endpoint]);
  const { trackFormSubmitError, trackFormSubmitSucces, trackFormSubmitProceed } =
    useTrackingContext();
  const amountOfSteps = initialForm.totalAmountOfSteps;
  const hasStepIndicator = amountOfSteps && amountOfSteps > 2 && currentPage !== amountOfSteps;

  const form = nextForm || initialForm;
  const mappedPrefix = encodeNameToReactHookFormFormat(form.htmlPrefix);

  React.useEffect(() => {
    tracker.setFormData(
      form.formItemId.value,
      form.formSessionId.value,
      form.metadata.isTrackingEnabled
    );
  }, [form.formItemId.value, form.formSessionId.value, form.metadata.isTrackingEnabled, tracker]);

  const flattenedFields = React.useMemo(() => flattenNestedFields(form.fields), [form.fields]);

  const initialValues = React.useMemo(
    () => createInitialValues(flattenedFields),
    [flattenedFields]
  );

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
  });

  useTrackingFormTouch({
    fields: methods.formState.touchedFields,
    wizardName: form.metadata.name,
    wizardStep: '1',
  });

  if (!form.metadata) {
    return <div>Form data invalid. Forget to set the rendering contents resolver?</div>;
  }

  const qsLanguage = language ? `&sc_lang=${language}` : '';

  const action = `/api/jss/formbuilder?fxb.FormItemId=${form.metadata.itemId}&fxb.HtmlPrefix=${form.htmlPrefix}&sc_apikey=${sitecoreApiKey}&sc_itemid=${form.contextItemId}${qsLanguage}`;

  const submitHandler = async (
    formValues: Record<string, unknown>,
    action: string,
    step?: number
  ) => {
    handleOnSubmit('loading');

    const gotoStep = step ? currentPage + step : currentPage + 1;
    const result = await fetchFormData({
      action,
      form,
      formValues,
      mappedPrefix,
      step: step ?? 1,
    });

    if ('error' in result) {
      handleOnSubmit('error');
      const errorString = Object.values(result?.validationErrors ?? {}).flat();

      if (errorString.length > 0) {
        trackFormSubmitError({
          formName: form.metadata.name,
          errorString: errorString.join(', '),
        });
      }

      methods.setError(result.error[0], result.error[1]);
      return;
    }

    if ('redirectUrl' in result && result.redirectUrl) {
      router.push(result.redirectUrl);
      trackFormSubmitSucces({ formName: form.metadata.name, stepIndex: gotoStep });
      handleOnSubmit('success');

      return;
    }

    if (!result.nextForm) {
      handleOnSubmit('error');
      handleValidationErrors(result, mappedPrefix, methods.setError);
      const message = JSON.stringify([
        'Er is iets misgegaan bij het versturen van het formulier. Probeer het later opnieuw.',
      ]);

      trackFormSubmitError({
        formName: form.metadata.name,
        errorString: message,
      });

      methods.setError(`root.${mappedPrefix}`, {
        message: message,
      });
      return;
    }

    // If its not the last step, we need to track the proceed event
    // If its the last step, we need to track the submit event
    if (gotoStep !== amountOfSteps) {
      trackFormSubmitProceed({
        formName: form.metadata.name,
        stepName: `Stap ${gotoStep}`,
        stepIndex: gotoStep,
      });
    } else {
      trackFormSubmitSucces({ formName: form.metadata.name, stepIndex: gotoStep });
      handleOnSubmit('success');
    }

    setNextForm(result.nextForm);
    setCurrentPage(gotoStep);
    handleValidationErrors(result, mappedPrefix, methods.setError);
  };

  const stepHandler = async (step?: number) => {
    // If the step is greater than 0, we need to trigger validation because we go to the next step.
    // Otherwise the form will not validate when the user clicks on the back button.
    const nextStep = step && step > 0;
    const hasErrors = nextStep
      ? await methods.trigger().then(() => {
          return !methods.formState.isValid;
        })
      : false;

    if (hasErrors) {
      return;
    }

    const formValues = methods.getValues();

    submitHandler(formValues, action, step ?? -1);
  };

  const onSubmit: SubmitHandler<Record<string, unknown>> = async (data, event) => {
    submitHandler(data, event?.target.action, 1);
  };

  const formFields = formFieldsFactory(
    form.fields,
    tracker,
    currentPage,
    amountOfSteps ?? 0,
    stepHandler
  );

  // Get general form errors
  const parsedGeneralFormErrors: string[] = JSON.parse(
    methods.formState.errors?.root?.[mappedPrefix]?.message || '[]'
  );

  const fieldsWithValues = getValueFields(flattenNestedFields(form.fields));

  const nameToFieldKeyMap = createNameToFieldKeyMap(Object.keys(initialValues), fieldsWithValues);
  const initialActionMap = createInitialActions({
    initialValues,
    fields: fieldsWithValues,
    nameToFieldKeyMap,
  });

  const mappedFieldConditions = createMappedFieldConditions(flattenedFields);

  return (
    <FormProvider {...methods}>
      <FormConditionsProvider
        nameToFieldKeyMap={nameToFieldKeyMap}
        initialActionMap={initialActionMap}
        conditionsMap={mappedFieldConditions}
      >
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          action={action}
          aria-describedby={
            parsedGeneralFormErrors && parsedGeneralFormErrors.length ? 'formErrors' : undefined
          }
          ref={formRef}
          noValidate
        >
          {hasStepIndicator ? (
            <Box paddingBottom={{ initial: 4, md: 16 }}>
              <StepIndicator
                currentPage={currentPage}
                totalAmountOfSteps={amountOfSteps ?? 0}
                handlePageChange={(step) => stepHandler(step)}
              />
            </Box>
          ) : null}
          <FormErrors id={'formErrors'} errors={parsedGeneralFormErrors} />
          <Stack gap={6}>{formFields}</Stack>
          <Box paddingBottom={6} />
        </form>
      </FormConditionsProvider>
    </FormProvider>
  );
};

/**
 * Factory function to create the form fields
 * @param fields - The fields to create
 * @param tracker - The form tracker
 * @param stepBackHandler - The step back handler
 * @returns The form fields
 */
export function formFieldsFactory(
  fields: Field[] = [],
  tracker: FormTracker,
  currentPage: number,
  totalAmountOfSteps: number,
  stepBackHandler: (step?: number) => void
) {
  return fields.map((field) => {
    const type = field.model.fieldTypeItemId as MapperKeys;
    const Component = mapper[type] as any; // We're not differentiating between different types of fields, so any is fine

    if (!Component) {
      return (
        <div key={field.model.itemId} style={{ border: '1px solid red', padding: 10 }}>
          <b>Geen mapping gevonden voor {type}</b>
        </div>
      );
    }

    if (type === FieldTypes.Section) {
      return (
        <Component
          field={field}
          tracker={tracker}
          currentPage={currentPage}
          totalAmountOfSteps={totalAmountOfSteps}
          key={field.model.itemId}
          stepBackHandler={stepBackHandler}
        />
      );
    }

    if (type === FieldTypes.Button && isButtonField(field)) {
      return (
        <Component
          field={field}
          tracker={tracker}
          currentPage={currentPage}
          totalAmountOfSteps={totalAmountOfSteps}
          key={field.model.itemId}
          onClick={stepBackHandler}
        />
      );
    }

    return <Component field={field} tracker={tracker} key={field.model.itemId} />;
  });
}
