import { ComponentRendering, Field, HtmlElementRendering, Item } from "@sitecore-jss/sitecore-jss-nextjs";
import { isComponentRendering } from "./isComponentRendering";

export const enrichPlaceholdersComponentData = (
  placeholders: (ComponentRendering | HtmlElementRendering)[],
  values: {
    [key: string]: Field | Item | Item[] | undefined;
  }
): (ComponentRendering | HtmlElementRendering)[] => {
  const components = placeholders?.map((component) => {
    if (isComponentRendering(component)) {
      return {
        ...component,
        fields: {
          ...component.fields,
          ...values,
        },
      };
    }

    return component;
  });

  return components as (ComponentRendering | HtmlElementRendering)[];
};
