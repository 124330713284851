import { CountryCodesProps } from "@ads-core/types";
import { countryCodes } from "@ads-core/data";
import { isTelephoneServiceNumber } from "./utils";

/**
 * Apply C4 rules for phone Telephone fields
 * If a service number is detected, return the original value
 * Starts with +[countrycode]
 * Phone number doesn't start with a 0 example 06 should be 6
 * There should be a space between the countrycode and the phone number
 * @param value - The value to apply the rules to
 * @param countryCode - The country code
 * @returns The formatted phone number
 */
export const telephoneC4Rules = (value: string, countryCode: CountryCodesProps | undefined) => {
  if (!value) return '';

  const cleanString = value.replace(/[-\s+]/g, '');
  const isServiceNumber = isTelephoneServiceNumber(cleanString);

  if(isServiceNumber) {
    return cleanString;
  }

  if (!countryCode) {
    const otherCountryCode = countryCodes.find(
      (code) => cleanString.slice(0, code.length) === code
    );
    
    return formatPhoneNumber(cleanString, otherCountryCode ? otherCountryCode : '');
  }

  return formatPhoneNumber(cleanString, countryCode?.countryCodes);
};

/**
 * Apply C4 rules for telephone fields with a country code selected
 * @param cleanString - The value to apply the rules to
 * @param countryCode - The country code
 * @returns The formatted phone number
 */
const formatPhoneNumber = (cleanString: string, countryCode: string | undefined) => {
  const firstNumber = cleanString.charAt(0);
  const selectedCountryCode = countryCode ? `+${countryCode} ` : '';
  const restOfNumber = cleanString.slice(1, cleanString.length);

  if (firstNumber.startsWith('0')) {
    return `${selectedCountryCode}${restOfNumber}`;
  }

  return `${selectedCountryCode}${firstNumber}${restOfNumber}`;
}
