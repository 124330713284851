import clsx from 'clsx';
import React from 'react';
import { cloneElement } from 'react';
import { ToneFocus } from '../ToneFocusProvider';
import { ArrowDownIcon, ArrowRightIcon } from '../../icons/index';
import * as styles from './AnimatedArrow.css';

type AnimatedArrowProps = {
  className?: string;
  active?: boolean;
  link?: React.ReactElement;
  label: string;
} & styles.AnimatedArrowVariants;

const getTone = (color: AnimatedArrowProps['color']) => {
  switch (color) {
    case 'onLight':
      return 'onLight';
    case 'onDark':
      return 'onDark';
    case 'ghostOnDark':
      return 'onDark';
    default:
      return 'onLight';
  }
};

export const AnimatedArrowComponent = React.forwardRef<HTMLDivElement, AnimatedArrowProps>(
  (props, ref) => {
    const classNames = styles.AnimatedArrow({ ...props });
    const tone = getTone(props.color);
    const icons =
      !props.direction || props.direction === 'leftToRight' ? (
        <>
          <ArrowRightIcon className={clsx(styles.flyBase, styles.flyIn)} size="xs" />
          <ArrowRightIcon className={clsx(styles.flyBase, styles.flyOut)} size="xs" />
        </>
      ) : (
        <>
          <ArrowDownIcon className={clsx(styles.flyBase, styles.flyInTopToBottom)} size="xs" />
          <ArrowDownIcon className={clsx(styles.flyBase, styles.flyOutTopToBottom)} size="xs" />
        </>
      );

    const AnimatedArrow = cloneElement(
      props.link ? props.link : <></>,
      undefined,
      <div
        ref={ref}
        className={clsx(styles.arrowWrap, props.className, {
          active: props.active,
          controlled: props.active !== undefined,
        })}
        aria-label={props.label}
      >
        <div className={clsx(classNames, styles.innerWrap)}>{icons}</div>
      </div>
    );

    return <ToneFocus tone={tone}>{AnimatedArrow}</ToneFocus>;
  }
);

